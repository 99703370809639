@import '../../pages/MainPage/style-guide.scss';
@import './modal/header_modal.scss';

.navigation__content {
    flex: 12;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navigation__content-375{
    display: none;
}

.navigation__container {
    width: 100%;
    height: 80px;
    background-color: $white;
    display: flex;
    padding: 10px;
}

.navigation__item-link{
    color: #343743;
    font-family: 'Inter-SemiBold';
    letter-spacing: 0.02em;
    transition: 300ms;
}

@media screen and (min-width: 1921px) {
    .navigation__logo{
        background: url('./ADVA_icon_large.png') no-repeat;
        width: 58px;
        height: 58px;
    }
    
    .navigation__links {
        display: flex;
        white-space: nowrap;
    }

    .navigation__item-link{
        font-size: 20px;
        line-height: 30px;
    }
    
    .navigation__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-darkest-grey;
        border-radius: 8px;
        width: 300px;
        padding: 10px;
    }

    .navigation__button-text{
        font-family: 'Inter';
        font-style: normal;
        cursor: pointer;
        color: $white;
        letter-spacing: 0.02em;
        font-weight: 600;
        font-size: 20px;
        line-height: 148%;
    }
    
    .navigation__wrapper-button {
        position: absolute;
        right: 10%;
        display: flex;
    }
}

@media screen and (max-width: 1920px) and (min-width: 1367px) {

    .navigation__container {
        width: 100%;
        height: 80px;
        background-color: $white;
        display: flex;
    }

    .navigation__logo{
        background: url('./ADVA_icon_large.png') no-repeat;
        width: 58px;
        height: 58px;
    }
    
    .navigation__links {
        display: flex;
        white-space: nowrap;
    }

    .navigation__item-link{
        font-size: 20px;
        line-height: 30px;
    }
    
    .navigation__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-darkest-grey;
        border-radius: 8px;
        width: 300px;
        padding: 10px;
    }
    
    .navigation__wrapper-button {
        position: absolute;
        right: 10%;
        display: flex;
    }
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {

    .navigation__container {
        width: 100%;
        height: 80px;
        background-color: $white;
        display: flex;
    }

    .navigation__logo{
        background: url('./ADVA_icon_medium.png') no-repeat;
        width: 48px;
        height: 48px;
    }
    
    .navigation__links {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
    }

    .navigation__item-link{
        font-size: 18px;
        line-height: 27px;
    }
    
    .navigation__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-darkest-grey;
        border-radius: 8px;
        width: 260px;
        padding: 10px;
    }
    
    .navigation__wrapper-button {
        position: absolute;
        right: 10%;
        display: flex;
    }
}

@media screen and (max-width: 1024px) and (min-width: 801px) {

    .navigation__container {
        width: 100%;
        height: 80px;
        background-color: $white;
        display: flex;
    }

    .navigation__logo{
        background: url('./ADVA_icon_medium.png') no-repeat;
        width: 48px;
        height: 48px;
    }
    
    .navigation__links {
        display: flex;
        white-space: nowrap;
    }

    .navigation__item-link{
        font-size: 16px;
        line-height: 24px;
    }
    
    .navigation__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-darkest-grey;
        border-radius: 8px;
        width: 260px;
        padding: 10px;
    }
    
    .navigation__wrapper-button {
        position: absolute;
        right: 10%;
        display: flex;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px){
    .navigation__container {
        width: 100%;
        height: 60px;
        background-color: $white;
        display: flex;
        padding: 0 16px;
    }

    .navigation__content-375{
        display: flex;
        align-items: center;
    }

    .navigation__content-375-burger{
        display: flex;
    }

    .navigation__content-375-logo{
        display: flex;
        margin-left: 20px;
    }

    .navigation__logo{
        background: url('./ADVA_icon_medium.png') no-repeat;
        width: 48px;
        height: 48px;
    }
    
    .navigation__links {
        // display: flex;
        white-space: nowrap;
        display: none;
    }

    .navigation__item-link{
        color: #343743;
        font-size: 18px;
    }
    
    .navigation__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-darkest-grey;
        border-radius: 8px;
        width: 260px;
        padding: 10px;
    }
    
    .navigation__wrapper-button {
        position: absolute;
        right: 10%;
        display: flex;
        display: none;
    }
}

@media screen and (max-width: 375px) {
    .navigation__container {
        width: 100%;
        height: 60px;
        background-color: $white;
        display: flex;
        padding: 0 16px;
    }

    .navigation__content-375{
        display: flex;
        align-items: center;
    }

    .navigation__content-375-burger{
        display: flex;
    }

    .navigation__content-375-logo{
        display: flex;
        margin-left: 20px;
    }

    .navigation__logo{
        background: url('./ADVA_icon_medium.png') no-repeat;
        width: 48px;
        height: 48px;
    }
    
    .navigation__links {
        white-space: nowrap;
        display: none;
    }

    .navigation__item-link{
        color: #343743;
        font-size: 18px;
    }
    
    .navigation__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-darkest-grey;
        border-radius: 8px;
        width: 260px;
        padding: 10px;
    }
    
    .navigation__wrapper-button {
        position: absolute;
        right: 10%;
        display: flex;
        display: none;
    }
}