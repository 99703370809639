@import '../../style-guide';

.introduction__container {
  position: relative;
  display: flex;
  background-color: $primary-accent;
  height: 920px;
  min-height: 920px;
  overflow-x: hidden;
}

.introduction__shapes-container {
  position: absolute;
  height: 920px;
  background-image: url(../../../images/InfoProviders/introduction-shapes.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.introduction__resorts {
  width: 660px;
  margin-top: 108px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 12px;
  column-gap: 8px;
}


.introduction__resort {
  background-color: #06A79C;
  padding: 4px 6px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  color: #FFFFFF;
}

.introduction__content-container {
  width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  z-index: 5;
}

.introduction__main-img-container {
  position: relative;
}

.introduction__main-img {
  width: 742px;
  height: 100%;
  background-image: url(../../../images/InfoProviders/photo-rafting.png);
  background-repeat: no-repeat;
  z-index: 5;
}

.introduction__adva-img {
  position: absolute;
  top: 76px;
  right: 0;
  width: 256px;
}

.introduction__logo {
  & path {
    fill: white;
  }
  width: 58px;
  height: 58px;
}

.introduction__logo-container {
  display: flex;
  align-items: center;
  gap: 19px;
  margin-top: 18px;
  margin-left: 46px;
}

.introduction__logo-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.315em;
  text-transform: uppercase;
  color: #D9F7F4;
}

.introduction__info-container {
  margin-top: 168px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.introduction__info-adva-title-wrapper {
  display: flex;
  align-items: baseline;
  gap: 9px;
}

.introduction__info-adva-title {
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 82px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFCB31;
}

.introduction__info-adva-title-postfix {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #FFFFFF;
}

.introduction__info {
  margin-top: 12px;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 64px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.introduction__register-btn {
  display: flex;
  align-items: center;
  margin-top: 23px;
  padding-left: 19px;
  background: #FFCB31;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #343743;

  &:hover {
    background: #F5D53B;
  };

  &:active {
    background: #F9BF16;
  }
}

.introduction__register-btn-arrow {
  margin-left: 15px;
}

.introduction__icons {
  margin: -116px 232px 0 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & a:hover {
    & .introduction__icon {
      & path:first-child {
        fill: #5F626E;
      }
      & rect:first-child {
        fill: #5F626E;
      }
    }
  }

  & a:active {
    & .introduction__icon {
      & path:first-child {
        fill: #4E515D;
      }
      & rect:first-child {
        fill: #4E515D;
      }
    }
  }
}

.introduction__text-container {
  width: 820px;
}

.introduction__account-btn {
  position: absolute;
  top: 20px;
  left: 146px;
  display: flex;
  align-items: center;
  padding: 0 41px 0 0;
  background: #FFCB31;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #343743;

  &:hover {
    background: #F5D53B;
  };

  &:active {
    background: #F9BF16;
  }

  & .introduction__account-btn-icon {
    margin-right: 33px;
  }
}

.introduction__main-img-xs-wrapper {
  display: none;
}

.introduction__account-btn-xs {
  display: none;
}

.introduction__resorts-xs {
  display: none;
}

.introduction__download-link {
  display: flex;
  margin-top: 16px;
  cursor: default;
  text-underline-offset: 3px;
  height: 32px;

  & a {
    display: none;// flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #FFFFFF;
    z-index: 30;
  }
}

@media screen and (min-width: 1919px) {
  .introduction__shapes-container {
    width: 61.5%;
  }

  .introduction__content-container {
    width: 1920px;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1365px) {
  .introduction__container {
    height: 656px;
    min-height: 656px;
  }

  .introduction__content-container {
    width: 1366px;
  }

  .introduction__main-img {
    width: 528px;
    background-image: url(../../../images/InfoProviders/photo-rafting-1366.png);
    background-size: cover;
  }

  .introduction__shapes-container {
    height: 656px;
    width: 65%;
  }

  .introduction__logo-container {
    margin-left: 18px;
  }

  .introduction__info-container {
    margin-top: 89px;
  }

  .introduction__icons {
    margin: -138px 75px 0 46px;
  }

  .introduction__info-adva-title {
    font-size: 36px;
    line-height: 46px;
  }

  .introduction__info-adva-title-postfix {
    font-size: 18px;
    line-height: 23px;
  }

  .introduction__info {
    margin-top: 30px;
    font-size: 36px;
    line-height: 46px;
  }

  .introduction__text-container {
    width: 582px;
  }

  .introduction__logo {
    width: 42px;
    height: 42px;
  }

  .introduction__logo-text {
    font-size: 10px;
    line-height: 13px;
  }

  .introduction__resorts {
    margin-top: 67px;
  }

  .introduction__adva-img {
    height: 450px;
    width: 150px;
  }

  .introduction__account-btn {
    padding: 0 9px 0 0;
    left: 119px;

    & svg {
      height: 37px;
      width: 37px;
    }

    & .introduction__account-btn-icon {
      margin-right: 7px;
    }
  }

  .introduction__download-link {
    margin-top: 24px;

    & a {
      font-size: 18px;
      line-height: 29px;
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 1023px) {
  .introduction__container {
    height: 729px;
    min-height: 729px;
  }

  .introduction__content-container {
    width: 1024px;
  }

  .introduction__main-img {
    width: 383px;
    background-image: url(../../../images/InfoProviders/photo-rafting-1024.png);
    background-size: cover;
  }

  .introduction__shapes-container {
    height: 729px;
    width: 65%;
  }

  .introduction__info-container {
    margin-top: 100px;
    margin-left: 100px;
    flex-direction: column;
    align-items: flex-start;
  }

  .introduction__icons {
    position: absolute;
    bottom: 154px;
    margin: 38px 0 0 0;
    order: 2;
    flex-direction: row;
  }

  .introduction__info-adva-title {
    font-size: 36px;
    line-height: 46px;
  }

  .introduction__info-adva-title-postfix {
    font-size: 18px;
    line-height: 23px;
  }

  .introduction__info {
    margin-top: 12px;
    font-size: 33px;
    line-height: 42px;
  }

  .introduction__text-container {
    width: 508px;
  }

  .introduction__logo-container {
    margin-left: 18px;
  }

  .introduction__resorts {
    margin-top: 124px;
    width: 560px;
  }

  .introduction__adva-img {
    height: 400px;
    width: 98px;
    top: 150px;
    right: 20px;
  }

  .introduction__account-btn {
    left: 26px;
  }

  .introduction__download-link {
    margin-top: 22px;

    & a {
      font-size: 18px;
      line-height: 29px;
    }
  }
}

@media screen and (max-width: 1023px) and (min-width: 374px) {
  .introduction__container {
    height: 677px;
    min-height: 677px;
    background-color: #00BFB4;
  }

  .introduction__content-container {
    position: relative;
    width: 375px;
    padding: 0 16px;
  }

  .introduction__main-img-container {
   display: none;
  }

  .introduction__shapes-container {
    display: none;
  }

  .introduction__info-container {
    margin-top: 34px;
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .introduction__icons {
    position: absolute;
    bottom: 158px;
    margin: 0;
    order: 2;
    flex-direction: row;
    align-self: flex-start;
    gap: 17px;

    & a:hover {
      & .introduction__icon {
        & path:first-child {
          fill: #393C48;
        }
        & rect:first-child {
          fill: #393C48;
        }
      }
    }

    & a:active {
      & .introduction__icon {
        & path:first-child {
          fill: #4E515D;
        }
        & rect:first-child {
          fill: #4E515D;
        }
      }
    }
  }

  .introduction__info-adva-title {
    font-size: 24px;
    line-height: 31px;
  }

  .introduction__info-adva-title-postfix {
    font-size: 14px;
    line-height: 18px;
  }

  .introduction__info {
    margin-top: 6px;
    font-size: 30px;
    line-height: 38px;
  }

  .introduction__text-container {
    width: 340px;
  }

  .introduction__logo-container {
    margin-left: 0;
    margin-top: 42px;
  }

  .introduction__logo-text {
    display: none;
  }

  .introduction__logo {
    width: 58px;
    height: 58px;
  }

  .introduction__logo-text {
    font-size: 10px;
    line-height: 13px;
  }

  .introduction__resorts {
    margin-top: 86px;
    width: 223px;
  }

  .introduction__resort {
    font-size: 18px;
    line-height: 22px;
  }

  .introduction__adva-img {
    height: 400px;
    width: 98px;
    top: 120px;
  }

  .introduction__main-img-xs-wrapper {
    position: absolute;
    left: 50%;
    display: block;
    width: 375px;
    height: 677px;
    transform: translateX(-50%);
  }

  .introduction__main-img-xs {
    display: none;
    width: 100%;
    height: 677px;
    background-image: url(../../../images/InfoProviders/photo-girl-snowboard.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -1;
  }

  .introduction__account-btn-xs {
    display: block;
    position: absolute;
    top: 40px;
    right: 16px;

    &:active svg path {
      fill: #F9BF16;
    }
  }

  .introduction__register-btn {
    margin-top: 6px;
  }

  .introduction__download-link {
    margin-top: 6px;

    & a {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 375px) and (min-width: 320px) {
  .introduction__container {
    height: 687px;
    min-height: 686px;
    background-color: #00BFB4;
  }

  .introduction__content-container {
    position: relative;
    width: 100%;
    padding: 0 16px;
  }

  .introduction__main-img-container {
    display: none;
  }

  .introduction__shapes-container {
    display: none;
  }

  .introduction__info-container {
    margin-top: 32px;
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .introduction__icons {
    position: absolute;
    bottom: 164px;
    margin: 0;
    order: 2;
    flex-direction: row;
    align-self: flex-start;
    gap: 17px;
  }

  .introduction__info-adva-title {
    font-size: 24px;
    line-height: 31px;
  }

  .introduction__info-adva-title-postfix {
    font-size: 14px;
    line-height: 18px;
  }

  .introduction__info {
    margin-top: 6px;
    font-size: 28px;
    line-height: 36px;
  }

  .introduction__text-container {
    width: 300px;
  }

  .introduction__logo-container {
    margin-left: 0;
    margin-top: 26px;
  }

  .introduction__logo-text {
    display: none;
  }

  .introduction__logo {
    width: 58px;
    height: 58px;
  }

  .introduction__logo-text {
    font-size: 10px;
    line-height: 13px;
  }

  .introduction__resorts {
    margin-top: 120px;
    width: 100px;
  }

  .introduction__resort {
    font-size: 12px;
    line-height: 14px;
  }

  .introduction__adva-img {
    height: 400px;
    width: 98px;
    top: 120px;
  }

  .introduction__main-img-xs-wrapper {
    position: absolute;
    left: 50%;
    display: block;
    width: 100%;
    height: 670px;
    transform: translateX(-50%);
  }

  .introduction__main-img-xs {
    display: none;
    width: 100%;
    height: 670px;
    background-image: url(../../../images/InfoProviders/photo-girl-snowboard.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -1;
  }

  .introduction__account-btn-xs {
    display: block;
    position: absolute;
    top: 30px;
    right: 16px;

    &:active svg path {
      fill: #F9BF16;
    }
  }

  .introduction__register-btn {
    margin-top: 12px;
    padding-left: 8px;
  }

  .introduction__register-btn-arrow {
    margin-left: 8px;
  }

  .introduction__download-link {
    margin-top: 24px;

    & a {
      font-size: 14px;
      line-height: 20px;

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
