
.meeting-point__container {
  overflow: hidden;
  max-width: 100%;
}

.meeting-point__tabs-container {
  width: 434px;
  margin-bottom: 20px;
}

.meeting-point__custom {
  color: #5C6E7F;
}

@media screen and (max-width: 840px) and (min-width: 395px) {
  .meeting-point__tabs-container {
    margin: 0 16px 20px 16px;
    width: unset;
    max-width: 434px;
    overflow-x: auto;
  }

  .meeting-point__custom {
    padding: 0 16px;
  }
}

@media screen and (max-width: 395px) and (min-width: 320px) {
  .meeting-point__tabs-container {
    margin: 0 0 20px 0;
    width: unset;
    max-width: unset;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }

  .meeting-point__container {
    & .rate-radio__rate {
      &:first-child,
      &:last-child {
        border-radius: 0;
      }
    }
  }

  .meeting-point__custom {
    padding: 0 16px;
  }
}
