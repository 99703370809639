$white: #ffffff;
$secondary-accent: #FF5995;
$primary-accent: #00BFB4;
$primary-accent-light: #CEECEB;
$primary-accent-light-pressed: #BEE2E1;
$primary-accent-pressed: #00A196;
$primary-accent-hover: #05C4B9;
$text-headers: #343743;
$text-labels: #92A4B5;
$text-labels-disabled: #B9CBDC;
$text-button-disabled: #8EA0B1;
$text-normal: #5C6E7F;
$text-dark-background: #ffffff;
$icon-inactive: #92A4B5;
$icon-dark-background: #ffffff;
$icon-light-background: #343743;
$background-grey-normal: #EAEDF2;
$background-grey-pressed: #DCE1EA;
$background-grey-hover: #E2E6ED;
$background-white-normal: #ffffff;
$background-white-pressed: #F0F4F8;
$background-white-hover: #F6F7FA;
$button-normal: #393C48;
$button-disabled: #AAAEBD;
$button-pressed: #4E515D;
$button-hover: #5F626E;
$input-active-stroke: #C4D6E7;
$input-warning-error: #D63854;
$input-warning-error-pressed: #BA1E3A;
$input-warning-error-hover: #E1425F;

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

.header1 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.015em;
}

.header2 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
}

.normal-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
}

.label-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

.extra-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
}

.button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.underlined {
  text-decoration: underline;
  text-underline-offset: 3px;
}
