.slots__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-top: 40px;
}

.slots__add-slots-btn {
  width: 268px;
}

.slots__container {
  margin-top: 16px;

  &.operator {
    margin-top: 36px;
  }
}

.slots__buttons-xs {
  display: none;
}

.slots__container-wrapper {
  & .services__empty_message {
    // display: none;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .slots__container-wrapper {
    // min-height: calc(100vh - 307px);
  }

  .slots__container {
    margin-top: 0;

    &.operator {
      margin-top: 0;

      & ~ .edit-slot__table-wrapper {
        margin-bottom: 16px;
      }
    }
  }

  .slots__buttons {
    display: none;
  }

  .slots__buttons-xs {
    display: block;
    padding: 16px;
    width: 100%;

    & .slots__add-slots-btn {
      width: 100%;
    }
  }

  .slots__container-wrapper {
    & .services__empty_message {
      display: block;
      margin: 0;
      padding-top: 16px;
    }
  }
}
