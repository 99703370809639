@import '../style-guide.scss';

@media screen and (min-width: 1921px) {

    .nums__container {
        width: 100%;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 55px 0 80px 0;
        flex: 3;
    }
    
    .nums__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 10;
    }
    
    .nums__number-title {
        color: $font-grey;
        font-weight: 600;
        font-size: 26px;
        line-height: 160%;
        letter-spacing: 0.01em;
        margin-top: -20px;
        text-align: center;
        white-space: nowrap;
    }
    
    .nums__number-item {
        flex-direction: column;
    }

    .nums__number-icon-200{
        background: url('./images/Group_200_large.png') no-repeat;
        background-size: cover;
        width: 205px;
        height: 155px;
    }

    .nums__number-icon-17{
        background: url('./images/Group_17_large.png') no-repeat;
        background-size: cover;
        width: 116px;
        height: 154px;
    }

    .nums__number-icon-11{
        background: url('./images/Group_11_large.png') no-repeat;
        background-size: cover;
        width: 98px;
        height: 155px;
    }
}

@media screen and (max-width: 1920px) and (min-width: 1367px) {

    .nums__container {
        width: 100%;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 55px 0 80px 0;
        flex: 3;
    }
    
    .nums__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 10;
    }
    
    .nums__number-title {
        color: $font-grey;
        font-weight: 600;
        font-size: 26px;
        line-height: 160%;
        letter-spacing: 0.01em;
        margin-top: -20px;
        text-align: center;
        white-space: nowrap;
    }
    
    .nums__number-item {
        flex-direction: column;
    }

    .nums__number-icon-200{
        background: url('./images/Group_200_large.png') no-repeat;
        background-size: cover;
        width: 205px;
        height: 155px;
    }

    .nums__number-icon-17{
        background: url('./images/Group_17_large.png') no-repeat;
        background-size: cover;
        width: 116px;
        height: 154px;
    }

    .nums__number-icon-11{
        background: url('./images/Group_11_large.png') no-repeat;
        background-size: cover;
        width: 98px;
        height: 155px;
    }
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {

    .nums__container {
        width: 100%;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 55px 0 80px 0;
        flex: 3;
    }
    
    .nums__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 10;
    }
    
    .nums__number-title {
        color: $font-grey;
        font-weight: 600;
        font-size: 26px;
        line-height: 160%;
        letter-spacing: 0.01em;
        margin-top: -20px;
        text-align: center;
        white-space: nowrap;
    }
    
    .nums__number-item {
        flex-direction: column;
    }

    .nums__number-icon-200{
        background: url('./images/Group_200_large.png') no-repeat;
        background-size: cover;
        width: 205px;
        height: 155px;
    }

    .nums__number-icon-17{
        background: url('./images/Group_17_large.png') no-repeat;
        background-size: cover;
        width: 116px;
        height: 154px;
    }

    .nums__number-icon-11{
        background: url('./images/Group_11_large.png') no-repeat;
        background-size: cover;
        width: 98px;
        height: 155px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 801px) {

    .nums__container {
        width: 100%;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 55px 0 80px 0;
        flex: 3;
    }
    
    .nums__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 10;
    }
    
    .nums__number-title {
        color: $font-grey;
        font-weight: 600;
        font-size: 26px;
        line-height: 160%;
        letter-spacing: 0.01em;
        margin-top: -20px;
        text-align: center;
        white-space: nowrap;
    }
    
    .nums__number-item {
        flex-direction: column;
    }

    .nums__number-icon-200{
        background: url('./images/Group_200_large.png') no-repeat;
        background-size: cover;
        width: 205px;
        height: 155px;
    }

    .nums__number-icon-17{
        background: url('./images/Group_17_large.png') no-repeat;
        background-size: cover;
        width: 116px;
        height: 154px;
    }

    .nums__number-icon-11{
        background: url('./images/Group_11_large.png') no-repeat;
        background-size: cover;
        width: 98px;
        height: 155px;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px){

    .nums__container {
        width: 100%;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 50px 16px;
        flex: 3;
    }
    
    .nums__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 10;
    }
    
    .nums__number-title {
        color: $font-grey;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.01em;
        margin-top: -20px;
        text-align: center;
        white-space: nowrap;
    }
    
    .nums__number-item {
        flex-direction: column;
    }

    .nums__number-icon-200{
        background: url('./images/Group_200_small.png') no-repeat;
        background-size: cover;
        width: 88px;
        height: 66px;
    }

    .nums__number-icon-17{
        background: url('./images/Group_17_small.png') no-repeat;
        background-size: cover;
        width: 70px;
        height: 65px;
    }

    .nums__number-icon-11{
        background: url('./images/Group_11_small.png') no-repeat;
        background-size: cover;
        width: 62px;
        height: 66px;
    }
}

@media screen and (max-width: 375px) {

    .nums__container {
        width: 100%;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 50px 16px;
        flex: 3;
    }
    
    .nums__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 10;
    }
    
    .nums__number-title {
        color: $font-grey;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.01em;
        margin-top: -20px;
        text-align: center;
        white-space: nowrap;
    }
    
    .nums__number-item {
        flex-direction: column;
    }

    .nums__number-icon-200{
        background: url('./images/Group_200_small.png') no-repeat;
        background-size: cover;
        width: 88px;
        height: 66px;
    }

    .nums__number-icon-17{
        background: url('./images/Group_17_small.png') no-repeat;
        background-size: cover;
        width: 70px;
        height: 65px;
    }

    .nums__number-icon-11{
        background: url('./images/Group_11_small.png') no-repeat;
        background-size: cover;
        width: 62px;
        height: 66px;
    }
}