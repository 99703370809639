.website__locations-container {
  margin-top: 40px;

  &.posts-container {
    margin-top: 32px;
  }
}

.website__add-post-btn {
  width: 240px;
  margin-bottom: 32px;
}

.website_edit-item-post-text-input {
  width: 100%;
}

.website_edit-item-post-text {
  &:not(.single-line) {
    min-height: 600px;
  }
}

.website_edit-item-button-wrapper {
  margin-top: 44px;
}

.website_edit-item-button {
  width: 332px;
}

.website__edit-item-date-input,
.date-picker__input.website__edit-item-date-input {
  width: 332px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .website__locations-container {
    margin-top: 0;

    &.posts-container {
      margin-top: 0;
    }
  }

  .website_edit-item-post-text {
    &:not(.single-line) {
      min-height: 200px;
    }
  }

  .website_edit-item-post-text-input {
    padding: 0 16px;
  }

  .website__edit-item-date-input,
  .date-picker__input.website__edit-item-date-input {
    width: 100%;
  }

  .website_edit-item-button {
    width: 100%;
  }
}
