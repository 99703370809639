@import '../../../style-guide.scss';

.slot__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 24px;
  border: 2px solid $background-grey-normal;
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    background: $background-white-hover;
  }

  &:active {
    background: $background-white-pressed;
  }
}

.slot__content {
  width: 100%;
}

.slot__first-row-wrapper {
  display: flex;
  margin-bottom: 18px;
  align-items: center;
  justify-content: space-between;
}

.slot__first-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.slot__first-row-dates {
  margin: 0 32px 0 8px;
  color: $text-headers;
}

.slot__first-row-weekdays-label {
  margin-right: 4px;
  color: $text-labels;
}

.slot__first-row-weekdays {
  color: $text-normal;
  text-transform: uppercase;
}

.slot__second-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
}

.slot__second-row-time {
  color: $text-labels;

  &.time-slot__container {
    height: 28px;
    width: unset;
    justify-content: space-around;
    gap: 7px;
  }
}

.slot__first-row-dates-xs {
  display: none;
}

.slot__first-row-weekdays-wrapper {
  display: flex;
  align-items: center;
}

.slot__first-row-icon {
  align-self: flex-start;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .slot__container {
    padding: 16px;
    margin-bottom: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid $background-grey-normal;
    border-radius: 0;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .slot__first-row {
    flex-wrap: wrap;
    row-gap: 21px;
    column-gap: 8px;
  }

  .slot__first-row-dates {
    display: none;
  }

  .slot__first-row-dates-xs {
    display: block;
    margin: 0 0 0 8px;
    color: $text-headers;
  }

  .slot__second-row {
    gap: 5px;
  }
}
