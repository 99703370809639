@import '../style-guide.scss';

@media screen and (min-width: 1921px){
  h1{
    font-family: 'Inter-ExtraBold';
    font-size: 55px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    line-height: 70px;
    margin: 0;
    transition: 300ms;
  }

  h2{
    font-family: 'Inter-Black';
    font-style: normal;
    text-transform: uppercase;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: 0.02em;
    margin: 0;
    padding: 0;
    transition: 300ms;
  }

  .header__link{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-size: 20px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: $text-dark;
    transition: 300ms;
  }

  .header__link:not(:first-child){
    margin-left: 20px;
  }

  .header__button-text{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    cursor: pointer;
    color: $white;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    transition: 300ms;
  }

  .article__title{
    font-family: 'Inter-SemiBold';
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.02em;
    transition: 300ms;
    color: #343743;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1367px){
  h1{
    font-family: 'Inter-ExtraBold';
    font-size: 55px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    line-height: 70px;
    margin: 0;
    transition: 300ms;
  }

  h2{
    font-family: 'Inter-Black';
    font-style: normal;
    text-transform: uppercase;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: 0.02em;
    transition: 300ms;
  }

  .header__link{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-size: 20px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: $text-dark;
    transition: 300ms;
  }

  .header__link:not(:first-child){
    margin-left: 20px;
  }

  .header__button-text{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    cursor: pointer;
    color: $white;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    transition: 300ms;
  }

  .article__title{
    font-family: 'Inter-SemiBold';
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.02em;
    transition: 300ms;
    color: #343743;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1024px){
  h1{
    font-family: 'Inter-ExtraBold';
    font-size: 55px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    line-height: 70px;
    margin: 0;
    transition: 300ms;
  }

  h2{
    font-family: 'Inter-Black';
    font-style: normal;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.02em;
    transition: 300ms;
  }

  .header__link{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-size: 20px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: $text-dark;
    transition: 300ms;
  }

  .header__link:not(:first-child){
    margin-left: 10px;
  }

  .header__button-text{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    cursor: pointer;
    color: $white;
    letter-spacing: 0.02em;
    font-size: 18px;
    line-height: 27px;
    transition: 300ms;
  }

  .article__title{
    font-family: 'Inter-SemiBold';
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 0.02em;
    transition: 300ms;
    color: #343743;
  }
}

@media screen and (max-width: 1024px) and (min-width: 801px){
  h1{
    font-family: 'Inter-ExtraBold';
    font-size: 48px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    line-height: 61px;
    max-width: 510px;
    margin: 0;
    transition: 300ms;
  }

  h2{
    font-family: 'Inter-Black';
    font-style: normal;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0.02em;
    transition: 300ms;
  }

  .header__link{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-size: 20px;
    line-height: 148%;
    letter-spacing: 0.02em;
    margin-left: 20px;
    color: $text-dark;
    transition: 300ms;
  }

  .header__link:not(:first-child){
    margin-left: 10px;
  }

  .header__button-text{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    cursor: pointer;
    color: $white;
    letter-spacing: 0.02em;
    font-size: 18px;
    line-height: 27px;
    transition: 300ms;
  }

  .article__title{
    font-family: 'Inter-SemiBold';
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.02em;
    transition: 300ms;
    color: #343743;
  }
}

@media screen and (max-width: 800px) and (min-width: 376px){
  h1{
    font-family: 'Inter-ExtraBold';
    font-size: 40px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    line-height: 50px;
    max-width: 510px;
    margin: 0;
    transition: 300ms;
  }

  h2{
    font-family: 'Inter-Black';
    font-style: normal;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0.02em;
    transition: 300ms;
  }

  .header__link{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-size: 20px;
    line-height: 148%;
    letter-spacing: 0.02em;
    margin-left: 20px;
    color: $text-dark;
    transition: 300ms;
  }

  .header__link:not(:first-child){
    margin-left: 20px;
  }

  .header__button-text{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    cursor: pointer;
    color: $white;
    letter-spacing: 0.02em;
    font-size: 18px;
    line-height: 27px;
    transition: 300ms;
  }

  .article__title{
    font-family: 'Inter-SemiBold';
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.02em;
    transition: 300ms;
    color: #343743;
  }
}

@media screen and (max-width: 375px){
  h1{
    font-family: 'Inter-ExtraBold';
    font-size: 36px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    line-height: 46px;
    margin: 0;
    transition: 300ms;
  }

  h2{
    font-family: 'Inter-Black';
    font-style: normal;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.02em;
    transition: 300ms;
  }

  .header__link{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-size: 20px;
    line-height: 148%;
    letter-spacing: 0.02em;
    margin-left: 20px;
    color: $text-dark;
    transition: 300ms;
  }

  .header__button-text{
    font-family: 'Inter-SemiBold';
    font-style: normal;
    cursor: pointer;
    color: $white;
    letter-spacing: 0.02em;
    font-size: 18px;
    line-height: 27px;
    transition: 300ms;
  }

  .article__title{
    font-family: 'Inter-SemiBold';
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.02em;
    transition: 300ms;
    color: #343743;
  }
}