@import '../../pages/style-guide';

.editable-div {
  width: 100%;
  padding: 10px 0;
  border-right: 11px solid $background-grey-normal;
  border-left: 11px solid $background-grey-normal;
  background: $background-grey-normal;
  border-radius: 8px;
  color: $text-normal;
  white-space: pre-wrap;

  &.disabled {
    background: none;
    padding: 10px 11px;
    border: 2px solid $background-grey-normal;
    color: $text-labels;
  }

  &:empty:before {
    content:attr(data-placeholder);
    color: $text-labels;
  }

  &:not(.disabled):hover {
    background-color: $background-grey-hover;
    border-right: 11px solid $background-grey-hover;
    border-left: 11px solid $background-grey-hover;
  }

  &:not(.disabled):active {
    background-color: $background-grey-pressed;
    border-right: 11px solid $background-grey-pressed;
    border-left: 11px solid $background-grey-pressed;
  }

  &:focus:not(.error) {
    box-shadow: 0 0 0 2px $input-active-stroke;
  }

  &.error {
    box-shadow: 0 0 0 1px $input-warning-error;
  }

  &:not(.single-line) {
    min-height: 124px;
  }
}

.single-line {
  white-space: nowrap;
  overflow: hidden;

  & br {
    display: none;
  }

  & * {
    display: inline;
    white-space: nowrap;
  }
}
