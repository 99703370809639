@import '../../pages/style-guide';

.main-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 12px;
  border-radius: 8px;
  font-family: inherit;

  &.primary {
    color: $text-dark-background;
    background: $button-normal;

    &:not(:disabled):hover {
      background: $button-hover;
    }

    &:not(:disabled):active {
      background: $button-pressed;
    }

    &:disabled {
      background: $button-disabled;
      cursor: default;
    }

    &.accent-button {
      background: $primary-accent;

      &:not(:disabled):hover {
        background: $primary-accent-hover;
      }

      &:disabled {
        background: #A2C2C1;
        cursor: default;
      }

      &:not(:disabled):active {
        background: $primary-accent-pressed;
      }
    }
  }

  &.secondary {
    color: $button-normal;
    background: #fff;
    border: 2px solid $button-normal;
    padding: 8px 12px;

    &:not(:disabled):hover {
      background: $background-white-hover;
    }

    &:not(:disabled):active {
      background: $background-white-pressed;
    }

    &:disabled {
      color: $button-disabled;
      border: 2px solid $button-disabled;
      cursor: default;

      & .main-button__icon-wrapper svg path {
        fill: $button-disabled;
      }
    }

    &.accent-button {
      color: $text-dark-background;
      background: $input-warning-error;
      padding: 10px 12px;
      border: none;

      &:not(:disabled):hover {
        background: $input-warning-error-hover;
      }

      &:not(:disabled):active {
        background: $input-warning-error-pressed;
      }

      &:disabled {
        color: $text-dark-background;
        background: #CBAEB3;
        cursor: default;

        & .main-button__icon-wrapper svg path {
          fill: $text-dark-background;
        }
      }
    }
  }
}

.main-button__icon-wrapper {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .main-button {

    &.primary {

      &:not(:disabled):hover {
        background: $button-normal;
      }

      &.accent-button {
        background: $primary-accent;

        &:not(:disabled):hover {
          background: $primary-accent;
        }
      }
    }

    &.secondary {

      &:not(:disabled):hover {
        background: #fff;
      }
    }
  }
}
