@import url('https://unpkg.com/leaflet@1.5.1/dist/leaflet.css');

.map__container {
  height: 423px;
  margin-bottom: 20px;
  z-index: 1;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .map__container {
    height: 248px;
  }
}
