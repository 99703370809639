@import '../../pages/style-guide.scss';

.return-link__container {
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  color: $primary-accent;
}

@media screen and (max-width: 1024px) and (min-width: 840px) {
  .return-link__text {
    display: none;
  }

  .return-link__container {
    position: fixed;
    top: 22px;
    z-index: 1001;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .return-link__text {
    display: none;
  }

  .return-link__container {
    position: fixed;
    left: 16px;
    top: 22px;
    z-index: 1001;
  }
}
