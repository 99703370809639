@import '../../../style-guide';

.booking__container {
  width: 100%;
  padding: 24px 20px;
  border: 2px solid $background-grey-normal;
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    background: $background-white-hover;
  }

  &:active {
    background: $background-white-pressed;
  }
}

.booking__id-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $text-headers;
  text-align: left;
}

.booking__details {
  display: flex;
  align-items: center;
  color: $text-normal;
}

.booking__details-icon {
  margin-right: 4px;
}

.booking__status {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: 40px;
  padding: 7px 6px;
  background: #D4DAE3;
  border-radius: 4px;
  color: #5C6E7F;

  &.customer-status {
    margin-right: 0;
  }

  &.created {
    background: #E4C1F0;
    color: #8B45B7;
    line-height: 14px;
  }

  &.approved {
    background: #B3E6CD;
    color: #218448;
    line-height: 14px;
  }

  &.rejected,
  &.canceled_by_customer,
  &.canceled_by_provider,
  &.not_provided {
    background: #EAC0CA;
    color: #853543;
    line-height: 14px;
  }

  &.review {
    background: #CCD9FB;
    color: #234E8E;
    line-height: 14px;
  }

  &.completed {
    line-height: 14px;
  }
}

.booking__status-container {
  display: flex;
  align-items: center;
}

.booking__statuses {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}


.booking__details-container {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.booking__details-container-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.booking__details-container-xs {
  display: none;
}

.booking__statuses-xs {
  display: none;
}

.booking__stars-row {
  margin: 16px 0;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .booking__container {
    padding: 16px;
    margin-bottom: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid $background-grey-normal;
    border-radius: 0;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .booking__statuses {
    display: none;
  }

  .booking__statuses-xs {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    & .booking__status.booking__statuses-xs-status {
      margin-right: 0;
    }
  }

  .booking__details-container {
    display: none;
  }

  .booking__details-container-xs {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 8px;
  }

  .booking__details-xs-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
