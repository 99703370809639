@import '../../pages/style-guide';

.registration-page__container {
  position: relative;
  width: 100%;
  height: max(100vh, 669px);
  background-color: #fff;

  &.confirm-container {
    height: max(100vh, 448px);
  }
}

.registration-page__content {
  position: absolute;
  top: 35%;
  left: 50%;
  padding: 60px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 343px;
  background: #fff;
  -webkit-transform: translate(-50%, -35%);
  transform: translate(-50%, -35%);

  &.confirm-content {
    width: 494px;
    top: 35%;
    -webkit-transform: translate(-50%, -35%);
    transform: translate(-50%, -35%);

    & .registration-page__logo-wrapper {
      margin-bottom: 47px;
    }
  }
}

.registration-page__logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.registration-text-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: $text-headers;
  gap: 12px;
}

.registration-page__logo {
  margin-right: 8px;
}

.registration-page__field {
  width: 100%;
  margin-bottom: 24px;
}

.registration-page__separator {
  width: 100%;
  height: 2px;
  margin-bottom: 24px;
  border-radius: 4px;
  background-color: $background-grey-normal;
}

.registration-page__register-btn {
  width: 100%;
}

.registration-page__policy {
  margin-top: 16px;
  color: $text-normal;
}

.login-page__policy-link {
  color: $primary-accent;
  text-underline-offset: 3px;
}

.confirmation-page__hint {
  width: 420px;
  text-align: center;
  color: $text-normal;

  & .bold-text {
    font-weight: 900;
  }
}

.confirmation-page__repeat-btn {
  margin-top: 32px;
  color: $primary-accent;

  &:disabled {
    color: $text-labels;
    cursor: default;
  }
}

.confirmation-page__code {
  margin-top: 52px;
  width: 252px;
}

.styles_react-code-input__CRulA {
  display: flex;
  align-items: center;
  gap: 8px;
}
//.styles_react-code-input-container__tpiKG
.confirmation-page__code .styles_react-code-input__CRulA > input {
  background-color: $background-grey-normal;
  border-radius: 8px;
  border: none;

  &:focus {
    border: none;
    box-shadow: 0 0 0 2px $input-active-stroke;
    caret-color: $primary-accent-pressed;
  }

  &:last-child {
    border: none;
    border-radius: 8px;
  }

  &:first-child {
    border: none;
    border-radius: 8px;
  }

  &::placeholder {
    color: $text-labels;
  }
}

.confirmation-page__code.error .styles_react-code-input__CRulA > input {
  border: 1px solid $input-warning-error;

  &:focus {
    box-shadow: none;
  }
}

.confirmation-page__time-row {
  margin-top: 12px;
  color: $text-labels;

  & .time-row-expired-code {
    color: $input-warning-error;
  }

  & .time-row-value {
    display: inline-block;
    width: 36px;
    text-align: right;
  }
}

.login-page__post-message {
  margin-top: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #92A4B5;
  // letter-spacing: 0.02em;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .registration-page__content {
    top: 0;
    left: 50%;
    padding: 72px 0;
    width: 100%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);

    &.confirm-content {
      width: 100%;
      top: 0;
      padding: 44px 0;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      align-items: flex-start;

      & .registration-page__logo-wrapper {
        margin-bottom: 71px;
      }
    }
  }

  .confirmation-page__hint {
    text-align: left;
  }
}
