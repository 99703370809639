@import '../../pages/style-guide';

.restore-page__container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  &.password-change-container {
    height: calc(100vh - 68px);
  }
}

.restore-page__content {
  position: absolute;
  top: 32%;
  left: 50%;
  padding: 60px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 421px;
  background: #fff;
  -webkit-transform: translate(-50%, -32%);
  transform: translate(-50%, -32%);

  &.confirm-content {
    width: 494px;
    top: 35%;
    -webkit-transform: translate(-50%, -35%);
    transform: translate(-50%, -35%);

    & .restore-page__logo-wrapper {
      margin-bottom: 47px;
    }
  }

  &.password-change-content{
    width: 343px;
    top: 32%;
    -webkit-transform: translate(-50%, -32%);
    transform: translate(-50%, -32%);
  }
}

.restore-page__logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.restore-text-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: $text-headers;
  gap: 12px;
}

.restore-page__logo {
  margin-right: 8px;
}

.restore-page__field {
  width: 343px;
  margin-bottom: 24px;
}

.restore-page__register-btn {
  width: 343px;
}

.restore-page__error-message {
  width: 343px;
  align-self: center;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .layout__content-container .restore-page__content {
    width: 100%;
    padding: 44px 0;
    top: 0;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);

    &.confirm-content {
      align-items: flex-start;
      width: 100%;
      top: 0;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);

      & .restore-page__logo-wrapper {
        margin-bottom: 71px;
      }

      & .registration-page__logo-wrapper {
        margin-bottom: 71px;
      }
    }

    &.password-change-content{
      width: 100%;
      top: 0;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
  }

  .restore-page__field {
    width: 100%;
  }

  .restore-page__register-btn {
    width: 100%;
  }

  .restore-page__error-message {
    width: 100%;
  }

  .restore-page__logo-wrapper {
    margin-bottom: 44px;
  }
}
