@import '../../../../style-guide';

.view-info__item-title.generic-service__guide-title {
  margin-bottom: 16px;
}

.view-info__item-title.generic-service__file-title {
  margin-bottom: 12px;
}

.generic-service__under-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: $text-labels;

  &.fitness-level-under-text {
    margin-top: 4px;
    max-width: 480px;
  }
}

.generic-service__guide-item {
  width: 354px;
}

.generic-service__people-amount {
  width: 343px;
}

.view-info__item-title.generic-service__duration-range-title {
  margin-bottom: 16px;
}

.generic-service__label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #92A4B5;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .generic-service__guide-item {
    width: unset;
  }

  .view-info__item-title.generic-service__file-title {
    padding: 0 16px;
  }

  .generic-service__people-amount {
    width: 100%;
  }

  .generic-service__under-text {
    &.fitness-level-under-text {
      max-width: 100%;
    }
  }

  .generic-service__amount,
  .generic-service__duration {
    padding-left: 0;
  }
}
