@import '../style-guide.scss';

.account-view__data {
  margin-top: 42px;
}

.account-view__security {
  margin-top: 60px;

  &.operator {
    margin-top: 0;
  }
}

.account-view__field-title {
  margin-bottom: 20px;
  color: $text-headers;
}

.account-view__field-value {
  margin-bottom: 32px;
  color: $text-normal;

  &.bold-value {
    font-weight: 700;
  }
}

.account-view__telegram-value {
  margin: 16px 0;
  color: $text-normal;
}

.account-view__telegram-timer {
  margin-top: 16px;
  color: $text-normal;

  & .time-row-value {
    display: inline-block;
    margin-left: 6px;
  }

  & .time-row-expired {
    color: $input-warning-error;
  }
}

.account-view__edit-btn {
  width: 150px;
  margin-bottom: 72px;
}

.account-view__security-title {
  margin-bottom: 40px;
}

.account-view__security-item {
  display: flex;
  margin-bottom: 42px;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  font-family: inherit;
  color: $button-normal;

  &.exit-item {
    margin-bottom: 0;
    color: $input-warning-error;
  }
}

.edit-account__title {
  margin-bottom: 40px;
}

.edit-account__field {
  width: 343px;
  margin-bottom: 24px;
}

.edit-account__buttons {
  display: flex;
  width: 343px;
  justify-content: space-between;
}

.edit-account__button {
  width: 100%;
}

.account-view__telegram-panel {
  margin: 40px 0 60px 0;
  width: 528px;
  padding: 16px;
  background: $background-grey-normal;
  border-radius: 8px;

  &.connected {
    width: 620px;
    background: transparent;
    padding: 0;
  }

  &.operator {
    background-color: white;
    border-radius: 0;
    padding: 0;
  }
}

.account-view__telegram-btn {
  width: 238px;

  &.disconnect {
    width: 358px;
  }
}

.account-view__service-fee {
  width: 126px;
  padding: 12px;
  background-color: $primary-accent-light;
  border-radius: 8px;
  color: $primary-accent;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .account-view__field-title {
    padding: 0 16px;
  }

  .account-view__field-value {
    padding: 0 16px;
  }

  .account-view__edit-btn {
    margin-left: 16px;
  }

  .account-view__telegram-panel {
    width: 100%;
    border-radius: 0;

    &.connected {
      width: 100%;
      padding: 0 16px 16px 16px;

      & .account-view__field-value {
        padding: 0;
      }
    }

    & .account-view__field-title {
      padding: 0;
    }

    &.operator {
      padding: 0 16px 16px 16px;
    }
  }

  .account-view__security-item {
    padding-left: 16px;

    &.exit-item {
      margin-bottom: 33px;
    }
  }

  .page__title.account-view__title {
    padding-bottom: 18px;
    box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
  }

  .page__title.edit-account__title {
    padding-bottom: 18px;
    box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
  }

  .edit-account__field {
    width: calc(100% - 32px);
    margin: 0 16px 24px 16px;
  }

  .edit-account__buttons {
    width: calc(100% - 32px);
    margin: 0 16px;
  }

  .account-view__service-fee {
    margin: 0 16px;
  }

  .account-view__telegram-btn {
    &.disconnect {
      width: 100%;
      max-width: 358px;
    }
  }

}
