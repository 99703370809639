@import '../../../style-guide.scss';

.review__container {
  width: 520px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.review__top-row {
  display: flex;
  align-items: center;
}

.review__name {
  margin-right: 8px;
  color: $text-headers;
}

.review__stars {
  display: flex;
  gap: 2px;
}

.review__date {
  margin-left: 16px;
  color: $text-labels;
}

.review__text {
  margin-top: 8px;
  color: $text-normal;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .review__container {
    padding-left: 16px;
    width: calc(100vw - 32px);
  }

  .review__top-row {
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .review__date {
    margin-left: 0;
  }

  .review__stars {
    margin-right: 16px;
  }
}