$white: #ffffff;
$text-dark: #343743;
$background-light-grey:#EAEDF2;
$background-dark-grey: #343743;
$background-darkest-grey:#393C48;
$font-grey:#5C6E7F;
$black-amber: #072D4B;

%title-font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 128%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}