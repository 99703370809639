@import '../../MainPage/style-guide.scss';


.services__container{
  display: flex;
  align-self: stretch;
  background-color: #EAEDF2;
  padding: 80px 0;
  flex: 1;
}

.services__content{
  flex: 10;
  display: flex;
  flex-direction: column;
}

.services__navigation-links{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 30px;
  a{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    margin-right: 10px;
    color: #92A4B5 !important;
  }
  p{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    margin-left: 10px;
    color: #00BFB4;
  }
}

.services__navigation-links_1024{
  display: none;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 30px;
  a{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #92A4B5 !important;
    white-space: nowrap;
  }
}

.services__header{
  display: flex;
  font-size: 48px;
  font-family: 'Inter-Black';
  line-height: 61px;
  letter-spacing: 0.02em;
  color: #343743;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.services__cards-page{
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  align-content: start;
  grid-gap: 20px;
  margin-top: 30px;
  transition: 300ms;
}

.services__single-card{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 470px;
  border-radius: 8px;
  transition: 300ms;
}

.services__single-card-footer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;
  background-color: $white;
  padding: 5px 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 110px;
  transition: 300ms;
}

.services__single-card-title {
  font-family: 'Inter-SemiBold';
  font-size: 24px;
  line-height: 36px;
  color: #343743;
  text-transform: uppercase;
  text-align: start;
  transition: 300ms;
}

.services__single-card-text {
  font-family: 'Inter-Regular';
  font-size: 20px;
  line-height: 30px;
  color: #A6A6A6;
  transition: 300ms;
}

.services__card-item-content{
  display: flex;
  flex-direction: row;
}

.services__card-item-pictire-container{
  flex: 1;
}

.services__card-item-pictire{
  width: 160px;
  height: 170px;
  border-radius: 8px;
}

.services__card-description{
 flex: 3;
  margin-left: 10px;
}

.services__card-description-top{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.services__card-description-bottom{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  width: 100%;
  margin-top: 10px;
}

.services__card-description-title {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Inter-SemiBold';
  font-size: 20px;
  line-height: 32px;
  color: $black-amber;
  text-align: start;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.services__card-description-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Inter-Regular';
  font-size: 18px;
  line-height: 29px;
  text-align: start;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #072D4B;
  opacity: 0.6;
}

@media screen and (max-width: 1366px) and(min-width: 1025px){
  .services__cards-page{
    grid-template-columns: repeat(4, 1fr);
  }

  .services__single-card{
    height: 405px;
  }

  .services__single-card-footer {
    height: 80px;
  }

  .services__single-card-title {
      font-size: 16px;
      line-height: 24px;
  }

  .services__single-card-text {
      font-size: 14px;
      line-height: 21px;
  }
}

@media screen and (max-width: 1024px) and(min-width: 801px){
  .services__navigation-links{
    display: none;
  }

  .services__navigation-links_1024{
    display: flex;
  }

  .services__container{
    padding: 60px 0;
  }

  .services__cards-page{
    grid-template-columns: repeat(4, 1fr);
  }

  .services__single-card{
    height: 270px;
  }

  .services__single-card-footer {
    height: 80px;
  }

  .services__single-card-title {
      font-size: 16px;
      line-height: 24px;
  }

  .services__single-card-text {
      font-size: 14px;
      line-height: 21px;
  }
}

@media screen and (max-width: 800px) and(min-width: 1px){
  .services__navigation-links{
    display: none;
  }

  .services__navigation-links_1024{
    display: flex;
  }

  .services__container{
    padding: 60px 16px;
  }

  .services__cards-page{
    grid-template-columns: repeat(2, 1fr);
  }

  .services__single-card{
    height: 250px;
  }

  .services__single-card-footer {
    height: 75px;
  }

  .services__single-card-title {
      font-size: 16px;
      line-height: 24px;
  }

  .services__single-card-text {
      font-size: 14px;
      line-height: 21px;
  }
}