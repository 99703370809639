
@font-face {
    font-family: 'Inter';
    src: url(./Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-Thin';
    src: url(./Inter/Inter-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-ExtraLight';
    src: url(./Inter/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-Light';
    src: url(./Inter/Inter-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-Regular';
    src: url(./Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-Medium';
    src: url(./Inter/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url(./Inter/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-Bold';
    src: url(./Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-ExtraBold';
    src: url(./Inter/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-Black';
    src: url(./Inter/Inter-Black.ttf) format('truetype');
}

