@import '../../style-guide';

.advantages__container {
  height: 744px;
  min-height: 744px;
  width: 100%;
  overflow-x: hidden;
  background-color: $primary-accent;
}

.advantages__content-container {
  position: relative;
  display: flex;
  width: 1920px;
  margin: 0 auto;
  gap: 87px;
}

.advantages__main-img {
  width: 906px;
  height: 744px;
  background-image: url(../../../images/InfoProviders/advantages-main-photo.png);
}

.advantages__message {
  position: absolute;
  left: 319px;
  top: 259px;
  width: 587px;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 61px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.advantages__icon-plus {
  position: absolute;
  left: 291px;
  top: 235px;
}

.advantages__blocks {
  display: flex;
  gap: 24px;
}

.advantages__blocks-column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 41px;

  &:nth-child(2) {
    margin-top: 90px;

    & .advantages__block {
      padding: 90px 27px 86px 32px;
    }
  }
}

.advantages__block {
  position: relative;
  width: 292px;
  padding: 72px 26px 62px 32px;
  border: 2px solid #FFCB31;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.01em;
  color: #FFFFFF;

  &:nth-child(2) {
    padding: 72px 27px 42px 32px;
  }
}

.advantages__block-plus {
  position: absolute;
  top: 13px;
  left: 14px;
}

@media screen and (max-width: 1919px) and (min-width: 1365px) {
  .advantages__content-container {
    width: 1366px;
    gap: 40px;
  }

  .advantages__main-img {
    width: 683px;
    background-image: url(../../../images/InfoProviders/advantages-main-photo-1366.png);
  }

  .advantages__message {
    width: 460px;
    font-size: 36px;
    line-height: 46px;
    top: 292px;
    left: 134px;
  }

  .advantages__icon-plus {
    left: 106px;
    top: 266px;
  }

  .advantages__block {
    width: 240px;
    font-size: 20px;
    line-height: 32px;
    padding: 72px 20px 62px 24px;

    &:nth-child(2) {
      padding: 55px 20px 42px 24px;
    }
  }

  .advantages__blocks-column {

    &:nth-child(2) {
      margin-top: 118px;

      & .advantages__block {
        padding: 76px 20px 76px 24px;
      }
    }
  }

  .advantages__block-plus {
    top: 16px;
    width: 17px;
    height: 17px;
  }

  .advantages__blocks {
    gap: 16px;
  }

  .advantages__blocks-column {
    gap: 16px;
  }
}

@media screen and (max-width: 1365px) and (min-width: 1023px) {
  .advantages__container {
    height: 655px;
    min-height: 655px;
  }

  .advantages__content-container {
    width: 1025px;
    gap: 40px;
  }

  .advantages__main-img {
    width: 464px;
    height: 655px;
    background-image: url(../../../images/InfoProviders/advantages-main-photo-1024.png);;
  }

  .advantages__message {
    width: 460px;
    font-size: 30px;
    line-height: 38px;
    top: 273px;
    left: 92px;
  }

  .advantages__icon-plus {
    left: 72px;
    top: 250px;
  }

  .advantages__block {
    width: 240px;
    font-size: 20px;
    line-height: 32px;
    padding: 32px 20px 0 24px;
    border: none;

    &:nth-child(2) {
      padding: 32px 20px 0 24px;
    }
  }

  .advantages__blocks-column {
    margin-top: 46px;
    gap: 116px;

    &:nth-child(2) {
      margin-top: 210px;
      gap: 140px;

      & .advantages__block {
        padding: 32px 20px 0 24px;
      }
    }
  }

  .advantages__block-plus {
    top: 16px;
    left: 4px;
    width: 16px;
    height: 16px;
  }

  .advantages__blocks {
    gap: 16px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 374px) {
  .advantages__container {
    height: 579px;
    min-height: 579px;
  }

  .advantages__content-container {
    width: 375px;
    gap: 0;

    &:after {
      position: absolute;
      bottom: -30px;
      right: 20px;
      height: 100px;
      width: 2px;
      background-color: #FFCB31;
      content: "";
    }
  }

  .advantages__main-img {
    display: none;
  }

  .advantages__message {
    width: 343px;
    font-size: 28px;
    line-height: 36px;
    top: 53px;
    left: 23px;
  }

  .advantages__icon-plus {
    left: 4px;
    top: 14px;
  }

  .advantages__block {
    width: 137px;
    font-size: 16px;
    line-height: 26px;
    padding: 12px 0 0 12px;
    border: none;
    margin-top: 32px;

    &:nth-child(2) {
      width: 158px;
      padding: 12px 0 0 12px;
      margin-top: 0;
    }
  }

  .advantages__blocks-column {
    margin-top: 0;
    gap: 36px;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;

    &:nth-child(2) {
      margin-top: 0;
      gap: 32px;

      & .advantages__block {
        width: 137px;
        padding: 12px 0 0 12px;
        margin-top: 42px;

        &:nth-child(2) {
          margin-top: -24px;
        }
      }
    }
  }

  .advantages__block-plus {
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
  }

  .advantages__blocks {
    gap: 0;
    margin-top: 178px;
    flex-direction: column;
  }
}

@media screen and (max-width: 374px) and (min-width: 320px) {
  .advantages__container {
    height: 579px;
    min-height: 579px;
  }

  .advantages__content-container {
    width: 100%;
    gap: 0;

    &:after {
      position: absolute;
      bottom: 4px;
      right: 20px;
      height: 100px;
      width: 2px;
      background-color: #FFCB31;
      content: "";
    }
  }

  .advantages__main-img {
    display: none;
  }

  .advantages__message {
    width: 290px;
    font-size: 24px;
    line-height: 32px;
    top: 53px;
    left: 23px;
  }

  .advantages__icon-plus {
    left: 4px;
    top: 14px;
  }

  .advantages__block {
    width: 117px;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 0 0 12px;
    border: none;
    margin-top: 46px;

    &:nth-child(2) {
      width: 128px;
      padding: 12px 0 0 12px;
      margin-top: 0;
    }
  }

  .advantages__blocks-column {
    margin-top: 0;
    gap: 32px;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;

    &:nth-child(2) {
      margin-top: 0;
      gap: 26px;

      & .advantages__block {
        width: 120px;
        padding: 12px 0 0 12px;
        margin-top: 62px;

        &:nth-child(2) {
          margin-top: 0;
        }
      }
    }
  }

  .advantages__block-plus {
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
  }

  .advantages__blocks {
    gap: 0;
    margin-top: 178px;
    flex-direction: column;
  }
}
