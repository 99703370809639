.qrcode__wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position:fixed;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
}

.qrcode__active {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position:absolute;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    z-index: 10;
}

.qrcode__content {
    height: 660px;
    width: 660px;
    background-color: #fff;
    display: flex;
    justify-content: center;
}

.qrcode__container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.qrcode__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 0%;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
    text-align: center;
}

.qrcode__text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    width: 389px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
}

.qrcode__close-icon{
    position: absolute;
    left: 460px;
    top: 30px;
    cursor: pointer;
}