@import '../../pages/style-guide.scss';

.weekdays-picker__container {
  position: relative;
  width: 370px;
}

.weekdays-picker__control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 12px;
  background: $background-grey-normal;
  border-radius: 8px;

  &:hover {
    background: $background-grey-hover;
  }

  &:active {
    background: $background-grey-pressed;
  };

  &.error {
    border: 1px solid $input-warning-error;
  }
}

.weekdays-picker__control-value {
  font-family: 'Inter', sans-serif;
  color: $text-headers;
  text-transform: uppercase;

  &.placeholder {
    text-transform: none;
    color: $text-labels;
  }
}

.weekdays-picker__control-btn {
  width: 100%;

  &:disabled .weekdays-picker__control {
    background: white;
    padding: 8px 12px;
    border: 1px solid $icon-inactive;
  }

  &:disabled .weekdays-picker__control-value {
    color: $icon-inactive;
  }

  &:disabled .weekdays-picker__arrow-icon path {
    fill: $icon-inactive;
  }
}

.weekdays-picker__menu-container {
  min-width: 130px;
  width: 100%;
  padding: 0;
}

.weekdays-picker__menu-item {
  padding: 8px 12px;

  &.checked {
    background-color: $primary-accent;
    color: white;
    border-bottom: 2px solid white;

    &:hover {
      background-color: $primary-accent-hover;
    }

    &:active {
      background-color: $primary-accent-pressed;
    }
  }

  &:not(.checked) {
    background-color: white;
    color: $button-normal;
    border-bottom: 2px solid $background-grey-normal;

    &:hover {
      background-color: $primary-accent-light;
    }

    &:active {
      background-color: $primary-accent-light-pressed;
    }
  }

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .weekdays-picker__menu-container {
    top: 36px;
    right: 0;
    left: 0;
  }

  .weekdays-picker__container {
    width: 100%;
    max-width: 370px;
  }
}
