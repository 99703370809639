@import '../../pages//MainPage/style-guide.scss';

// .footer__social_1024{
//     display: none;
// }

.footer__container {
    background-color: $background-dark-grey;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 60px 0 130px 0;
}

.footer__content {
    flex: 10;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
}

.footer__links-container{
    display: flex;
    flex-direction: row;
    align-self: stretch;
}

.footer__links-container-navigation{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.footer__links-container-navigation-link{
    font-family: 'Inter-Medium';
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #fff !important;
}

.footer__links-container-navigation-link:not(:last-child){
    margin-bottom: 20px;
}

.footer__links-container-navigation-icons{
    flex: 3;
    display: flex;
    flex-direction: row;
    align-self: stretch;
}

.footer__links-container-navigation-icons-container{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer__links-container-navigation-icon-google{
    background: url('../../images/LandingIcons/google_play_icon_large.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 180px;
    height: 52px;
    margin-right: 10px;
}

.footer__links-container-navigation-icon-appstore{
    background: url('../../images/LandingIcons/appstore_icon_large.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 180px;
    height: 52px;
    margin-right: 10px;
}

.footer__links-container-navigation-icon-qr{
    background: url('../../images/LandingIcons/qr_icon_large.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 120px;
    height: 52px;
    margin-right: 10px;
}

.footer__links-container-navigation-button-container{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer__links-container-navigation-button{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border: 2px solid #fff;
    border-radius: 8px;
    height: 52px;
    min-width: 280px;
    max-width: 450px;
}

.footer__links-container-navigation-button-text{
    font-family: 'Inter-SemiBold';
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #fff;
}

.footer__contacts-container-social-icons{
    display: none;
}

.footer__telegram__link-button{
    background: url('../../images/LandingIcons/Telegram_icon_medium.png') no-repeat; 
    width: 40px;
    height: 40px;
    margin-right: 30px;
}

.footer__vk__link-button{
    background: url('../../images/LandingIcons/VK_icon_medium.png') no-repeat; 
    width: 40px;
    height: 40px;
    margin-right: 30px;
}

.footer__r__link-button{
    background: url('../../images/LandingIcons/R_icon_medium.png') no-repeat; 
    width: 40px;
    height: 40px;
}

.footer__contacts-container{
    display: flex;
    flex-direction: row;
    align-self: stretch;
    margin-top: 50px;
}

.footer__contacts-container-contacts{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.footer__contacts-container-contacts-info-container{
    display: flex;
    flex-direction: column;
}

.footer__contacts-container-contacts-info-questions{
    font-family: 'Inter-Medium';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #92A4B5;
}

.footer__contacts-container-contacts-copyright{
    font-family: 'Inter-Bold';
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: #8C919A;
    margin-top: 40px;
}

.footer__contacts-container-contacts-info-email{
    font-family: 'Inter-SemiBold';
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #EAEDF2;
}

.footer__contacts-container-social{
    flex: 3;
    display: flex;
    flex-direction: row;
}

.footer__contacts-container-social-item{
    display: flex;
    align-items: flex-start;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #00BFB4;
    font-size: 20px;
    line-height: 32px;
    font-family: 'Inter-Medium';
}

.footer__contacts-container-social-item:not(:last-child){
    margin-right: 40px;
}

.footer__payment{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    right: 0;
}

.footer__payment-card-visa {
    background: url('./images/visa.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 108px;
    height: 34px;
}

.footer__payment-card-mastercard {
    margin-left: 107px;
    background: url('./images/master_card.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 75px;
    height: 45px;
}

.footer__payment-card-mir {
    margin-left: 107px;
    background: url('./images/mir.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 107px;
    height: 34px;
}

.footer__payment-card-paykeeper {
    margin-left: 107px;
    background: url('./images/paykeeper.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 112px;
    height: 35px;
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
    .footer__container {
        padding: 50px 0 65px 0;
    }

    .footer__links-container-navigation-link{
        font-size: 18px;
        line-height: 22px;
    }

    .footer__links-container-navigation-button-text{
        font-size: 18px;
        line-height: 27px;
    }

    .footer__payment-card-mastercard {
        margin-left: 70px;
    }
    
    .footer__payment-card-mir {
        margin-left: 70px;
    }
    
    .footer__payment-card-paykeeper {
        margin-left: 70px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 801px) {
    .footer__container {
        padding: 50px 0 65px 0;
    }

    .footer__links-container-navigation-link{
        font-size: 16px;
        line-height: 20px;
    }

    .footer__links-container-navigation-button-container{
        align-items: flex-end;
        justify-content: flex-start;
        flex-direction: column;
    }

    .footer__links-container-navigation-button{
        max-height: 55px;
        margin-bottom: 25px;
    }

    .footer__links-container-navigation-button-text{
        font-size: 16px;
        line-height: 24px;
    }

    .footer__links-container-navigation-icons{
        flex: 2;
    }

    .footer__links-container-navigation-icons-container{
        flex-direction: column;
    }

    .footer__links-container-navigation-icon-google{
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .footer__links-container-navigation-icon-appstore{
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .footer__links-container-navigation-icon-qr{
        margin-right: 0;
    }

    .footer__contacts-container{
        margin-top: 0;
    }

    .footer__contacts-container-social{
        display: none;
    }

    .footer__contacts-container-social-icons{
        display: flex;
    }

    .footer__payment-card-mastercard {
        margin-left: 10px;
    }
    
    .footer__payment-card-mir {
        margin-left: 10px;
    }
    
    .footer__payment-card-paykeeper {
        margin-left: 10px;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px){
    .footer__container {
        padding: 60px 16px;
    }

    .footer__links-container{
        flex-direction: column;
    }

    .footer__links-container-navigation-link{
        font-size: 16px;
        line-height: 20px;
    }

    .footer__links-container-navigation-button-container{
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 20px;
    }

    .footer__links-container-navigation-button{
        height: 55px;
        min-height: 55px;
        margin-bottom: 25px;
    }

    .footer__links-container-navigation-button-text{
        font-size: 16px;
        line-height: 24px;
    }

    .footer__links-container-navigation-icons{
        flex: 0;
        margin-top: 30px;
        flex-direction: column;
    }

    .footer__links-container-navigation-icons-container{
        flex-direction: column;
    }

    .footer__links-container-navigation-icon-google{
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .footer__links-container-navigation-icon-appstore{
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .footer__links-container-navigation-icon-qr{
        display: none;
    }

    .footer__contacts-container-contacts-info-container{
        margin-top: 30px;
    }

    .footer__contacts-container{
        margin-top: 0;
    }

    .footer__contacts-container-social{
        display: none;
    }

    .footer__contacts-container-social-icons{
        display: flex;
    }

    .footer__payment-card-mastercard {
        margin-left: 0;
    }
    
    .footer__payment-card-mir {
        margin-left: 0;
    }
    
    .footer__payment-card-paykeeper {
        margin-left: 0;
    }

    .footer__payment{
        flex-direction: column;
    }

    .footer__payment-card-visa {
        background: url('./images/visa.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 76px;
        height: 24px;
        margin-bottom: 20px;
    }
    
    .footer__payment-card-mastercard {
        margin-left: 0;
        background: url('./images/master_card.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 53px;
        height: 32px;
        margin-bottom: 20px;
    }
    
    .footer__payment-card-mir {
        margin-left: 0;
        background: url('./images/mir.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 75px;
        height: 24px;
        margin-bottom: 20px;
    }

    .footer__payment-card-paykeeper {
        margin-left: 0;
        background: url('./images/paykeeper.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 78px;
        height: 25px;
    }
}

@media screen and (max-width: 375px) {
    .footer__container {
        padding: 60px 16px;
    }

    .footer__links-container{
        flex-direction: column;
    }

    .footer__links-container-navigation-link{
        font-size: 16px;
        line-height: 20px;
    }

    .footer__links-container-navigation-button-container{
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 20px;
    }

    .footer__links-container-navigation-button{
        height: 55px;
        min-height: 55px;
        margin-bottom: 25px;
    }

    .footer__links-container-navigation-button-text{
        font-size: 16px;
        line-height: 24px;
    }

    .footer__links-container-navigation-icons{
        flex: 0;
        margin-top: 30px;
        flex-direction: column;
    }

    .footer__links-container-navigation-icons-container{
        flex-direction: column;
    }

    .footer__links-container-navigation-icon-google{
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .footer__links-container-navigation-icon-appstore{
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .footer__links-container-navigation-icon-qr{
        display: none;
    }

    .footer__contacts-container-contacts-info-container{
        margin-top: 30px;
    }

    .footer__contacts-container{
        margin-top: 0;
    }

    .footer__contacts-container-social{
        display: none;
    }

    .footer__contacts-container-social-icons{
        display: flex;
    }

    .footer__payment-card-mastercard {
        margin-left: 0;
    }
    
    .footer__payment-card-mir {
        margin-left: 0;
    }
    
    .footer__payment-card-paykeeper {
        margin-left: 0;
    }

    .footer__payment{
        flex-direction: column;
    }

    .footer__payment-card-visa {
        background: url('./images/visa.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 76px;
        height: 24px;
        margin-bottom: 20px;
    }
    
    .footer__payment-card-mastercard {
        margin-left: 0;
        background: url('./images/master_card.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 53px;
        height: 32px;
        margin-bottom: 20px;
    }
    
    .footer__payment-card-mir {
        margin-left: 0;
        background: url('./images/mir.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 75px;
        height: 24px;
        margin-bottom: 20px;
    }

    .footer__payment-card-paykeeper {
        margin-left: 0;
        background: url('./images/paykeeper.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 78px;
        height: 25px;
    }
}