@import '../../pages/style-guide';

.rate-radio__container {
  //
}

.rate-radio__label {
  margin-bottom: 8px;
  color: $text-normal;
}

.rate-radio__rates {
  display: flex;
  gap: 1px
}

.rate-radio__rate {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-basis: 120px;
  flex-grow: 1;
  background: $background-grey-normal;
  color: $text-headers;

  &:disabled {
    background: white;
    color: $text-labels;
    border: 1px solid $background-grey-normal;
    cursor: default;
  }

  &.active {
    background: $primary-accent;
    color: white;
    border: none;

    &:disabled {
      background: $primary-accent-light;
      color: $text-headers;
    }
  }

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }
}
