@import '../../pages/style-guide.scss';

.date-picker__input {
  width: 370px;
  height: 44px;
  padding: 10px 12px;
  background: $background-grey-normal;
  border-radius: 8px;
  text-indent: 32px;
  color: $text-headers;
  font-family: 'Inter', sans-serif;
  cursor: pointer;

  &:hover {
    background: $background-grey-hover;
  }

  &:active {
    background: $background-grey-pressed;
  }
}

.date-picker__input-wrapper {
  position: relative;
  height: 44px;
  display: flex;
  align-items: center;
}

.date-picker__calendar-icon {
  position: absolute;
  left: 12px;
  top: 10px;
}

.rdrMonthAndYearWrapper {
  & :nth-child(1) {
    order: 2;
  }

  & :nth-child(2) {
    order: 1;
  }

  & :nth-child(3) {
    order: 3;
  }
}

.rdrCalendarWrapper {
  width: 100%;
}

.edit-slot__calendar-container .rdrMonth {
  width: 100%;
  padding: 0 20px 20px 20px;
}

.edit-slot__calendar-container .rdrNextButton {
  background-image: url(../../images/components/DatePicker/right-arrow.svg);
  background-color: transparent;

  & i {
    display: none;
  }
}

.edit-slot__calendar-container .rdrPprevButton {
  background-image: url(../../images/components/DatePicker/left-arrow.svg);
  background-color: transparent;

  & i {
    display: none;
  }
}

.edit-slot__calendar-container .rdrNextPrevButton {
  margin: 0 6px;

  &:hover.rdrPprevButton {
    background-image: url(../../images/components/DatePicker/left-arrow.svg);
    background-color: $background-white-hover;
  }

  &:hover.rdrNextButton {
    background-image: url(../../images/components/DatePicker/right-arrow.svg);
    background-color: $background-white-hover;
  }
}

.edit-slot__calendar-container .rdrMonthAndYearWrapper {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.edit-slot__calendar-container .rdrMonthAndYearPickers {
  justify-content: flex-start;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #343743
}


.edit-slot__calendar-container .rdrCalendarWrapper {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #343743;
}

.edit-slot__calendar-container .rdrWeekDay {
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #92A4B5;
  text-transform: uppercase;
}

.edit-slot__calendar-container .rdrMonth .rdrWeekDays {
  padding: 16px 0;
}

.edit-slot__calendar-container .rdrDayNumber {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #343743;
}

.edit-slot__calendar-container .rdrDayToday .rdrDayNumber span:after {
  display: none;
}

.rdrEndEdge,
.rdrStartEdge,
.rdrInRange, {
  color: $primary-accent !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  border: none !important;
}

.rdrDayInPreview ~ .rdrDayNumber,
.rdrDayEndPreview ~ .rdrDayNumber,
.rdrDayStartPreview ~ .rdrDayNumber {
  background-color: $primary-accent-light;
}

.rdrStartEdge ~ .rdrDayNumber,
.rdrEndEdge ~ .rdrDayNumber,
.rdrInRange ~ .rdrDayNumber {
  background-color: $primary-accent;
}

.rdrDayPassive .rdrInRange ~ .rdrDayNumber {
  background-color: #92CDC9;
}

.rdrDay {
  &:not(.rdrDayEndOfWeek) .rdrStartEdge ~ .rdrDayNumber,
  &:not(.rdrDayEndOfWeek) .rdrDayStartPreview ~ .rdrDayNumber {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  &.rdrDayEndOfWeek .rdrStartEdge ~ .rdrDayNumber,
  &.rdrDayStartOfWeek .rdrEndEdge ~ .rdrDayNumber,
  &.rdrDayEndOfWeek .rdrDayStartPreview ~ .rdrDayNumber,
  &.rdrDayStartOfWeek .rdrDayEndPreview ~ .rdrDayNumber{
    border-radius: 4px !important;
  }

}

.rdrEndEdge ~ .rdrDayNumber,
.rdrDayEndPreview ~ .rdrDayNumber {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rdrDayStartPreview ~ .rdrDayNumber {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rdrDayStartOfWeek .rdrInRange ~ .rdrDayNumber,
.rdrDayStartOfWeek .rdrDayInPreview ~ .rdrDayNumber {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rdrDayEndOfWeek .rdrInRange ~ .rdrDayNumber,
.rdrDayEndOfWeek .rdrDayInPreview ~ .rdrDayNumber {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}


.rdrDayDisabled {
  background-color: transparent !important;
  cursor: default !important;

  & .rdrDayNumber span {
    color: $text-button-disabled !important;
  }
}

.rdrDayPassive .rdrDayNumber span {
  color: $text-labels-disabled !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay.rdrDayPassive .rdrInRange ~ .rdrDayNumber span {
  color: white !important;
}

.rdrDayHovered .rdrDayStartPreview,
.rdrInRange ~ .rdrDayStartPreview,
.rdrStartEdge ~ .rdrDayStartPreview,
.rdrEndEdge ~ .rdrDayStartPreview
{
  border-radius: 0 !important;
  background-color: transparent !important;
}

@media screen and (max-width: 400px) and (min-width: 320px) {
  .date-picker__input-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .edit-slot__calendar-container .rdrNextPrevButton {
    &:hover.rdrPprevButton {
      background-color: transparent;
    }

    &:hover.rdrNextButton {
      background-color: transparent;
    }

    &:active.rdrPprevButton {
      background-color: $background-white-hover;
    }

    &:active.rdrNextButton {
      background-color: $background-white-hover;
    }
  }
}
