@import '../style-guide';

.bookings__top-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bookings__top-row-title-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bookings__top-row-title {
  max-width: 590px;
}


.bookings__menu-tab-list {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
  padding: 0;
  list-style: none;
  border-bottom: 2px solid $background-grey-normal;

  &.message-shown {
    margin-top: 32px;
  }
}

.bookings__menu-tab {
  margin-left: 16px;
  padding: 8px 8px 21px 8px;
  cursor: pointer;
  color: $text-labels;

  &:first-child {
    margin-left: 8px;
  }

  &.react-tabs__tab--selected {
    position: relative;
    color: $text-headers;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      height: 4px;
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background-color: $primary-accent;
    }
  }
}

.service__blocking-message-container {
  margin: 41px 0 15px 0;
}

.service__blocking-message {
  min-height: 122px;
}

.service__blocking-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service__blocking-btn {
  width: 330px;
}

.service__blocking-cancel-btn {
  width: 148px;
}

.service__message {
  position: relative;
  width: 100%;
  margin-top: 24px;
  padding: 16px 80px 16px 16px;
  background: #E0E9F8;
  border-radius: 4px;

  &.rejected {
    background: #FEE9D6;
  }

  &.blocked {
    background: #FAE5E9;
  }
}

.service__message-title {
  color: $text-headers;
}

.service__message-text {
  margin-top: 12px;
  color: $text-normal;
}

.service__message-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  right: 16px;
}


.page__tab-list.bookings-list__tab-list {
  margin: 46px 0 47px 0;
}

.edit-service__return-link-xs {
  display: none;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .bookings__top-row-title-wrapper {
    flex-wrap: wrap;
  }

  .page__title.bookings__top-row-title{
    width: 100%;
  }

  .bookings__menu-tab-list {
    display: flex;
    margin: 16px 0 0 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }

  .page__tab-list.bookings-list__tab-list {
    margin: 0;
  }

  .bookings__menu-tab {
    padding: 8px;
  }

  .service__message {
    border-radius: 0;
  }

  .edit-service__return-link-xs {
    display: block;
  }

  .service__blocking-buttons {
    gap: 16px;
  }

  .service__blocking-btn,
  .service__blocking-cancel-btn {
    width: 100%;
  }
}
