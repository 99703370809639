@import './style-guide.scss';
@import '../MainPage/Usefull/usefullStyle';
@import '../MainPage/UserCategory/userCategoryStyle';
@import '../MainPage/Nums/numsStyle';
@import '../MainPage/MainBlock/mainBlockStyle.scss';
@import '../MainPage/Locations/locationsStyle';
@import '../MainPage/Download/downloadStyle';
@import '../MainPage/CardList/cardListStyle.scss';
@import '../MainPage/QrCode/qrCodeStyle.scss';
@import '../../components/Header/headerStyle.scss';
@import '../../components/Footer/footerStyle.scss';
@import './textStyles.scss';
@import '../Articles/locations_usefull_articles.scss';
@import '../Articles/UsefullCategories/usefull_categories.scss';
@import '../Articles/ServicesCategories/services_categories.scss';
@import '../Articles/LocationCategories/locations_categories.scss';

html{
  height: 100%;
}
#root{
  display: flex;
  flex-direction: column;
  height: 100%;
}

body{
  flex: 1;
  width: 100%;
  height: 100%;
}

body, p {
    margin: 0;
    padding: 0;
}

a:active,
a:hover,
a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

.block__title-light{
  color: $white;
  margin: 0;
}

.block__title-dark{
  color: $text-dark;
  margin: 0;
}
