@import '../../pages/style-guide';

.error-page__content {
  position: absolute;
  top: 39%;
  left: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #fff;
  -webkit-transform: translate(-50%, -39%);
  transform: translate(-50%, -39%);
}

.error-page__title {
  color: $text-headers;
}

.error-page__codes {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.error-page__code {
  color: $text-normal;
}

.error-page__info {
  margin-top: 24px;
  display: flex;
  align-items: center;
  max-width: 528px;
  justify-content: space-around;
  color: $text-normal;
  text-align: center;
}

.error-page__link {
  margin-top: 24px;
  color: $primary-accent;
}


