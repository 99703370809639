@import '../style-guide.scss';

.main__info-social-375{
    display: none;
}

.main__container {
    width: 100%;
    height: 784px;
    background-image: url('./background.png');
    background-size: cover;
    background-position: center;
    display: flex;
    transition: 300ms;
}

.main__content {
    flex: 12;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    overflow: hidden;
    transition: 300ms;
}

.main__info {
    flex: 5;
    display: flex;
    align-items: center;
    align-self: stretch;
}

.main__info-icon {
    margin-bottom: 24px;
}

.main__info-title {
    margin-bottom: 40px;
    min-width: 720px;
}

.colortext {
    color: #FFCB31;
}

.main__info-social {
    display: flex;
    flex-direction: column;
}

.main__phone{
    flex: 5;
    display: flex;
    position: relative;
}

.main__phone-picture {
    position: absolute;
    background: url('./Iphon.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 410px;
    height: 799px;
    bottom: -180px;
    margin-left: 40px;
    transition: 300ms;
    transition: 300ms;
}

.telegram__link-button{
    background: url('../../../images/LandingIcons/Telegram_icon_large.png'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 60px;
    height: 60px;
    transition: 300ms;
}

.vk__link-button{
    background: url('../../../images/LandingIcons/VK_icon_large.png'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 60px;
    height: 60px;
    transition: 300ms;
}

.r__link-button{
    background: url('../../../images/LandingIcons/R_icon_large.png'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 60px;
    height: 60px;
    transition: 300ms;
}

.main__info-link-google{
    background: url('../../../images/LandingIcons/googleplay_dark.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 178px;
    height: 50px;
    margin-right: 12px;
    box-shadow: 2px 2px 2px rgb(255 255 255 / 10%);
    border-radius: 4px;
    transition: 300ms;
}

.main__info-link-apple{
    background: url('../../../images/LandingIcons/appstore_dark.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 178px;
    height: 50px;
    margin-right: 12px;
    box-shadow: 2px 2px 2px rgb(255 255 255 / 10%);
    border-radius: 2px;
    transition: 300ms;
}

.main__info-link-qr{
    background: url('../../../images/LandingIcons/qr_dark.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 120px;
    height: 50px;
    box-shadow: 2px 2px 2px rgb(255 255 255 / 10%);
    border-radius: 2px;
    transition: 300ms;
}

.main__info-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.main__info-tag {
    background: #0B9483;
    border-radius: 4px;
    padding: 6px 12px;
    font-family: 'Inter-SemiBold';
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    margin-bottom: 8px;
    margin-right: 8px;
    transition: 300ms;
}


@media screen and (max-width: 1366px) and (min-width: 1025px) {
    .main__info-title {
        min-width: 0;
    }

    .telegram__link-button{
        width: 40px;
        height: 40px;
    }

    .vk__link-button{
        width: 40px;
        height: 40px;
    }

    .r__link-button{ 
        width: 40px;
        height: 40px;
    }

    .main__info-link-google{
        width: 178px;
        height: 50px;
    }

    .main__info-link-apple{
        width: 178px;
        height: 50px;
    }

    .main__info-link-qr{
        width: 120px;
        height: 50px;
    }

    .main__phone{
        flex: 4;
    }
}

@media screen and (max-width: 1024px) and (min-width: 801px) {
    .main__container {
        height: 655px;
    }

    .main__info-title {
        min-width: 450px;
    }

    .telegram__link-button{
        width: 40px;
        height: 40px;
    }

    .vk__link-button{
        width: 40px;
        height: 40px;
    }

    .r__link-button{
        width: 40px;
        height: 40px;
    }

    .main__info-link-google{
        width: 178px;
        height: 50px;
    }

    .main__info-link-apple{
        width: 178px;
        height: 50px;
    }

    .main__info-link-qr{
        width: 120px;
        height: 50px;
    }

    .main__info-tags {
        max-width: 510px;
    }

    .main__info-tag {
        font-size: 20px;
        line-height: 24px;
    }

    .main__phone {
        flex: 4;
    }

    .main__phone-picture {
        position: absolute;
        width: 370px;
        height: 700px;
        bottom: -140px;
        transition: 300ms;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px) {
    .main__info-title {
        min-width: 0;
    }

    .main__container {
        height: 582px;
        padding: 16px;
    }

    .main__info-icon {
        margin-right: 20px;
        margin-bottom: 0;
    }

    .telegram__link-button{
        width: 40px;
        height: 40px;
    }

    .vk__link-button{
        width: 40px;
        height: 40px;
    }

    .r__link-button{
        width: 40px;
        height: 40px;
    }

    .main__info-social-375{
        position: absolute;
        display: flex;
        flex-direction: row;
        bottom: 0;
    }

    .main__info-about {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        align-self: stretch;
        position: relative;
    }

    .main__info-title {
        margin-bottom: 20px;
    }

    .main__info-link-google{
        width: 152px;
        height: 44px;
    }

    .main__info-link-apple{
        width: 152px;
        height: 44px;
    }

    .main__info-link-qr{
        display: none;
    }

    .main__info-tags {
        margin-top: 20px;
    }

    .main__info-tag {
        font-size: 14px;
        line-height: 17px;
    }

    .main__phone {
        display: none;
    }
}

@media screen and (max-width: 375px) {
    .main__info-title {
        min-width: 0;
    }

    .main__container {
        height: 582px;
        padding: 16px;
    }

    .main__info-icon {
        margin-right: 20px;
        margin-bottom: 0;
    }

    .telegram__link-button{
        width: 40px;
        height: 40px;
    }

    .vk__link-button{
        width: 40px;
        height: 40px;
    }

    .r__link-button{
        width: 40px;
        height: 40px;
    }

    .main__info-social-375{
        position: absolute;
        display: flex;
        flex-direction: row;
        bottom: 0;
    }

    .main__info-about {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        align-self: stretch;
        position: relative;
    }

    .main__info-title {
        margin-bottom: 20px;
    }

    .main__info-link-google{
        width: 152px;
        height: 44px;
    }

    .main__info-link-apple{
        width: 152px;
        height: 44px;
    }

    .main__info-link-qr{
        display: none;
    }

    .main__info-tags {
        margin-top: 20px;
    }

    .main__info-tag {
        font-size: 14px;
        line-height: 17px;
    }

    .main__phone {
        display: none;
    }
}