@import '../style-guide.scss';

.services__controls-row {
  display: flex;
  margin-top: 35px;
  justify-content: space-between;
}

.services__search-input-wrapper {
  width: 280px;
}

.services__add-btn {
  width: 198px;
}

.services__add-btn-icon {
  margin-right: 8px;
}

.services__provider-info {
  display: flex;
  column-gap: 32px;
  margin: 36px 0 54px 0;
  padding: 15px 20px;
  background: $background-grey-normal;
  border-radius: 8px;
}

.services__provider-info-item-title {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  color: $text-headers;
}

.services__provider-info-item {
  min-width: 200px;
  color: $text-labels;

  & .bold-item {
    font-weight: bold;
  }
}

.services__empty_message {
  margin-top: 24px;
  color: $text-normal;
}

.services__add-btn-wrapper-xs {
  display: none;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .services__add-btn {
    display: none;
  }

  .services__controls-row {
    position: relative;
    width: 100%;
    padding: 16px 16px 20px 16px;
    margin-top: 0;
    box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
  }

  .services__search-input-wrapper {
    width: 100%;
  }

  .services__add-btn-xs {
    display: flex;
    width: 100%;
  }

  .services__add-btn-wrapper-xs {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    box-shadow: 0px -1px 6px rgba(52, 55, 67, 0.12);
  }

  .services__empty_message {
    padding: 0 16px;
  }

  .services__provider-info {
    flex-wrap: wrap;
    row-gap: 24px;
    border-radius: 0;
  }

  .services__provider-info-item-title {
    margin-bottom: 16px;
  }
}
