@import '../style-guide.scss';

.location__cards-item:nth-of-type(1n+4){
    display: none;
}

@media screen and (min-width: 1921px){
    .locations__container {
        background-color: $background-light-grey;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 80px 0 61px 0;
    }
    
    .location__content {
        flex: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .location__title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
    }
    
    .location__cards {
        display: flex;
        align-items: center;
        // justify-content: flex-start;
        align-self: stretch;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    
    .location__cards-item {
        flex: 1;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 8px;
        margin:0 10px 8px 0;
        transition: 300ms;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    }
    
    .location__cards-item_bottom{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        align-self: stretch;
        height: 90px;
        max-height: 90px;
        background-color: $white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-left: 20px;
    }
    
    .location__cards-item_title {
        font-family: 'Inter-SemiBold';
        color: $text-dark;
        font-size: 24px;
        letter-spacing: 0.015em;
        line-height: 29px;
    }
}

@media screen and (max-width: 1920px) and(min-width: 1367px){
    .locations__container {
        background-color: $background-light-grey;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 80px 0 61px 0;
    }
    
    .location__content {
        flex: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .location__title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
    }
    
    .location__cards {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    
    .location__cards-item {
        flex: 1;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 8px;
        margin:0 10px 8px 0;
        transition: 300ms;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    }
    
    .location__cards-item_bottom{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        height: 90px;
        background-color: $white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-left: 20px;
    }
    
    .location__cards-item_title {
        font-family: 'Inter-SemiBold';
        color: $text-dark;
        font-size: 24px;
        letter-spacing: 0.015em;
        line-height: 29px;
    }
}

@media screen and (max-width: 1366px) and(min-width: 1025px){
    .locations__container {
        background-color: $background-light-grey;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 60px 0 60px 0;
    }
    
    .location__content {
        flex: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .location__title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
    }
    
    .location__cards {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    
    .location__cards-item {
        flex: 1;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 8px;
        margin: 0 10px 8px 0;
        transition: 300ms;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .location__cards-item_bottom{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        height: 90px;
        background-color: $white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-left: 20px;
    }
    
    .location__cards-item_title {
        font-family: 'Inter-SemiBold';
        color: $text-dark;
        font-size: 24px;
        letter-spacing: 0.015em;
        line-height: 29px;
    }
}

@media screen and (max-width: 1024px) and(min-width: 801px){
    .locations__container {
        background-color: $background-light-grey;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 60px 0;
    }
    
    .location__content {
        flex: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .location__title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
    }
    
    .location__cards {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    
    .location__cards-item {
        flex: 1;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 8px;
        margin: 0 8px 8px 0;
        transition: 300ms;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .location__cards-item_bottom{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        height: 90px;
        background-color: $white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 0 15px;
    }
    
    .location__cards-item_title {
        font-family: 'Inter-SemiBold';
        color: $text-dark;
        font-size: 20px;
        letter-spacing: 0.015em;
        line-height: 24px;
        text-align: start;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px){
    .locations__container {
        background-color: $background-light-grey;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 60px 16px;
    }
    
    .location__content {
        flex: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-self: stretch;
        flex-wrap: nowrap;
        padding: 16px;
    }

    .location__title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
    }
    
    .location__cards {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    
    .location__cards-item {
        width: 100%;
        height: 310px;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 8px;
        margin-bottom: 8px;
        transition: 300ms;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .location__cards-item_bottom{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        height: 90px;
        background-color: $white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 0 15px;
    }
    
    .location__cards-item_title {
        font-family: 'Inter-SemiBold';
        color: $text-dark;
        font-size: 20px;
        letter-spacing: 0.015em;
        line-height: 24px;
        text-align: start;
    }
}

@media screen and (max-width: 375px){
    .locations__container {
        background-color: $background-light-grey;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 60px 0;
    }
    
    .location__content {
        flex: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-self: stretch;
        flex-wrap: nowrap;
        padding: 16px;
    }

    .location__title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
    }
    
    .location__cards {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    
    .location__cards-item {
        width: 100%;
        height: 225px;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 8px;
        margin-bottom: 8px;
        transition: 300ms;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .location__cards-item_bottom{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        height: 90px;
        background-color: $white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 0 15px;
    }
    
    .location__cards-item_title {
        font-family: 'Inter-SemiBold';
        color: $text-dark;
        font-size: 20px;
        letter-spacing: 0.015em;
        line-height: 24px;
        text-align: start;
    }
}
