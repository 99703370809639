@import '../../style-guide';

.service__container {
  width: 100%;
  padding: 20px 24px;
  border: 2px solid $background-grey-normal;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    background: $background-white-hover;
  }

  &:active {
    background: $background-white-pressed;
  }
}

.service__name-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.service__name-row-title {
  align-self: center;
  color: $text-headers;
  text-align: left;
  max-width: 500px;
}

.service__status-container {
  display: flex;
  align-items: center;
}

.service__status {
  align-self: self-start;
  margin-right: 2px;
  margin-top: 4px;
  padding: 6px 12px;
  color: $text-dark-background;
  border-radius: 4px;

  &.list__status {
    margin-right: 40px;
  }

  &.verification {
    background: #347EEC;
  }

  &.active {
    background: $primary-accent;
  }

  &.inactive {
    background: #7B98B4;
  }

  &.blocked {
    background: #D63854;
  }

  &.rejected {
    background: #EC9341;
  }

  &.unverified {
    background: #A655E6;
  }
}

.service__details-row {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.service__details-number {
  margin: 0 4px 0 8px;
  color: $text-normal;
}

.service__details-new-number {
  padding: 0 4px;
  color: $primary-accent;
  background: $primary-accent-light;
  border-radius: 4px;
}

.service__details-column {
  display: flex;
  align-items: center;
  height: 26px;

  &:not(:last-child) {
    margin-right: 24px;
  }
}

.service__status-xs {
  display: none;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .service__container {
    padding: 16px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid $background-grey-normal;
    border-radius: 0;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .list__status {
    display: none;
  }

  .service__status-xs {
    display: inline-block;
    margin-top: 17px;
  }

  .service__status {
    margin-top: 0;
    margin-left: 16px;

    &.list__status-xs {
      margin-left: 0;
    }
  }

  .service__details-column {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}
