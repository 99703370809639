.steps__container {
  position: relative;
  height: 1654px;
  min-height: 1654px;
  width: 100%;
  overflow-x: hidden;
}

.steps__overlay {
  position: absolute;
  height: 1654px;
  width: 100%;
  background-color: rgba(63, 66, 78, 0.9);
  z-index: 5;
}

.steps__main-img {
  position: absolute;
  width: 1920px;
  height: 1654px;
  background-image: url(../../../images/InfoProviders/steps-main-page.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.steps__shapes {
  position: absolute;
  left: calc(calc(1920px - 100vw)/2);
  width: 100vw;
  height: 1654px;
  background-image: url(../../../images/InfoProviders/steps-shapes.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 7;
}

.steps__content-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1920px;
  height: 1654px;
  margin: 0 auto;
  z-index: 10;
}

.steps__title {
  margin-top: 84px;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 61px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFCB31;
  z-index: 10;
}

.steps__blocks {
  width: 1304px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 53px;
  padding-right: 29px;
  z-index: 10;
  row-gap: 49px;
  column-gap: 144px;
}

.steps__step1-img {
  width: 346px;
  height: 470px;
  background-image: url(../../../images/InfoProviders/steps-step1-img.png);
}

.steps__step2-img {
  width: 453px;
  height: 314px;
  background-image: url(../../../images/InfoProviders/steps-step2-img.png);
}

.steps__step3-img {
  width: 485px;
  height: 366px;
  background-image: url(../../../images/InfoProviders/steps-step3-img.png);
}

.steps__step4-img {
  width: 363px;
  height: 484px;
  background-image: url(../../../images/InfoProviders/steps-step4-img.png);
}

.steps__block {
  position: relative;

  &.step1-block {
    padding-left: 106px;
    padding-top: 50px;
  }

  &.step2-block {
    padding-left: 122px;
    padding-top: 98px;
    margin-top: 104px;
  }

  &.step3-block {
    padding-left: 100px;
    padding-top: 58px;
  }

  &.step4-block {
    padding-left: 112px;
    padding-top: 38px;
    margin-top: 80px;
  }
}

.steps__number {
  position: absolute;
  left: 0;
  top: 0;
}

.steps__block-text {
  margin-top: 25px;
  margin-left: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.01em;
  color: #FFFFFF;

  &.step1-text {
    width: 423px;
  }

  &.step2-text {
    width: 467px;
  }

  &.step4-text {
    width: 386px;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1365px) {
  .steps__content-container {
    width: 1366px;
  }

  .steps__main-img {
    width: 1366px;
  }

  .steps__shapes {
    left: calc(calc(1366px - 100vw) / 2);
  }
}

@media screen and (max-width: 1365px) and (min-width: 1023px) {
  .steps__content-container {
    width: 1024px;
    height: 963px;
  }

  .steps__main-img {
    display: none;
  }

  .steps__shapes {
    left: calc(calc(1024px - 100vw) / 2);
    height: 963px;
  }

  .steps__container {
    height: 963px;
    min-height: 963px;
  }

  .steps__overlay {
    height: 963px;
  }

  .steps__step1-img {
    width: 175px;
    height: 220px;
    background-size: cover;
  }

  .steps__step2-img {
    width: 227px;
    height: 158px;
    background-size: cover;
  }

  .steps__step3-img {
    width: 219px;
    height: 155px;
    background-size: cover;
  }

  .steps__step4-img {
    width: 189px;
    height: 250px;
    background-size: cover;
  }

  .steps__blocks {
    width: 840px;
    padding-right: 0;
    column-gap: 74px;
    row-gap: 0;
  }

  .steps__block-text {
    margin-top: 18px;
    margin-left: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;

    &.step1-text {
      width: 274px;
    }

    &.step2-text {
      width: 278px;
    }

    &.step4-text {
      width: 274px;
    }
  }

  .steps__block {

    &.step1-block {
      padding-left: 106px;
      padding-top: 10px;
    }

    &.step2-block {
      padding-left: 90px;
      padding-top: 10px;
      margin-top: 0;
    }

    &.step3-block {
      padding-left: 132px;
      padding-top: 98px;
    }

    &.step4-block {
      padding-left: 102px;
      padding-top: 8px;
      margin-top: 0;
      margin-left: 28px;
    }
  }

  .steps__number {
    top: -20px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 374px) {
  .steps__content-container {
    position: relative;
    width: 375px;
    height: 822px;

    &:after {
      position: absolute;
      bottom: 20px;
      left: 20px;
      height: 100px;
      width: 2px;
      background-color: #FFCB31;
      content: "";
      z-index: 20;
    }
  }

  .steps__main-img {
    display: none;
  }

  .steps__shapes {
    left: calc(calc(375px - 100vw) / 2);
    height: 822px;
  }

  .steps__container {
    height: 822px;
    min-height: 822px;
  }

  .steps__overlay {
    height: 822px;
  }

  .steps__step1-img {
    display: none;
  }

  .steps__step2-img {
    display: none;
  }

  .steps__step3-img {
    display: none;
  }

  .steps__step4-img {
    display: none;
  }

  .steps__blocks {
    flex-direction: column;
    width: 335px;
    padding-right: 0;
    column-gap: 0;
    row-gap: 0;
    margin-top: 37px;
  }

  .steps__block-text {
    margin-top: 0;
    margin-left: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    &.step1-text {
      width: 162px;
    }

    &.step2-text {
      width: 146px;
    }

    &.step3-text {
      width: 109px;
    }

    &.step4-text {
      width: 157px;
    }
  }

  .steps__block {
    &.step1-block {
      padding-left: 72px;
      padding-top: 10px;
    }

    &.step2-block {
      padding-left: 0;
      padding-top: 120px;
      margin-top: 20px;
      align-self: flex-end;
    }

    &.step3-block {
      padding-left: 82px;
      padding-top: 28px;
    }

    &.step4-block {
      padding-left: 0;
      padding-top: 120px;
      margin-top: 0;
      align-self: flex-end;
      margin-left: 28px;
    }
  }

  .steps__number {
    top: -30px;
  }

  .steps__title {
    margin-top: 38px;
    font-size: 28px;
    line-height: 36px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 374px) and (min-width: 320px) {
  .steps__content-container {
    position: relative;
    width: 100%;
    height: 822px;

    &:after {
      position: absolute;
      bottom: 20px;
      left: 20px;
      height: 100px;
      width: 2px;
      background-color: #FFCB31;
      content: "";
      z-index: 20;
    }
  }

  .steps__main-img {
    display: none;
  }

  .steps__shapes {
    left: 0;
    height: 822px;
  }

  .steps__container {
    height: 822px;
    min-height: 822px;
  }

  .steps__overlay {
    height: 822px;
  }

  .steps__step1-img {
    display: none;
  }

  .steps__step2-img {
    display: none;
  }

  .steps__step3-img {
    display: none;
  }

  .steps__step4-img {
    display: none;
  }

  .steps__blocks {
    flex-direction: column;
    width: calc(100% - 40px);
    padding-right: 0;
    column-gap: 0;
    row-gap: 0;
    margin-top: 37px;
  }

  .steps__block-text {
    margin-top: 0;
    margin-left: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    &.step1-text {
      width: 162px;
    }

    &.step2-text {
      width: 146px;
    }

    &.step3-text {
      width: 109px;
    }

    &.step4-text {
      width: 157px;
    }
  }

  .steps__block {
    &.step1-block {
      padding-left: 72px;
      padding-top: 10px;
    }

    &.step2-block {
      padding-left: 0;
      padding-top: 120px;
      margin-top: 20px;
      align-self: flex-end;
    }

    &.step3-block {
      padding-left: 82px;
      padding-top: 28px;
    }

    &.step4-block {
      padding-left: 0;
      padding-top: 120px;
      margin-top: 0;
      align-self: flex-end;
      margin-left: 28px;
    }
  }

  .steps__number {
    top: -30px;
  }

  .steps__title {
    margin-top: 38px;
    font-size: 26px;
    line-height: 34px;
    padding-right: 0;
  }
}
