@import '../../pages/style-guide';

.layout__header-container {
  position: fixed;
  width: 100%;
  height: 68px;
  background: white;
  z-index: 1000;
}

.layout__header {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
}

.layout__logo {
  position: absolute;
  left: 100px;
}

.layout__burger-menu {
  display: none;
}

.layout__username {
  position: absolute;
  right: 92px;
  display: flex;
  padding: 10px 8px;
  align-items: center;
  border-radius: 8px;
  color: $button-normal;
  font-family: 'Inter', sans-serif;

  &:hover {
    background: $background-white-hover;
  }

  &:active {
    background: $background-white-pressed;
  }
}

.layout__settings-btn {
  display: flex;
}

.layout__settings-icon {
  margin-left: 12px;
}

.layout__content-container {
  position: relative;
  width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 121px 0 38px 0;

  &:not(.error).unauthorized,
  &.centered-content {
    padding: 0;
  }
}

.layout__header-content {
  width: 800px;
  margin: 0 auto;
}

.layout__support-container {
  position: fixed;
  bottom: 44px;
  right: 44px;
  z-index: 100;
}

.layout__support-button {
  position: relative;
  cursor: pointer;
  z-index: 150;

  &:hover svg path:first-child {
    fill: $primary-accent-hover;
  };

  &:active svg path:first-child {
    fill: $primary-accent-pressed;
  };
}

.layout__support-menu {
  min-width: 376px;
  margin-top: 0;
  padding: 36px 32px 20px 32px;
  bottom: 9px;
  right: 5px;
  cursor: default;
  border: 1px solid $background-grey-normal;
  box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
  border-radius: 8px 8px 34px 8px;
}

.layout__support-menu-title {
  margin-bottom: 28px;
  color: $text-headers;
}

.layout__support-menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: $text-normal;
}

.layout__support-menu-item-icon {
  margin-right: 12px;
}

.layout__header-menu-item {
  margin-right: 42px;
  color: $text-labels;

  &.active {
    color: $text-headers;
  }
}

.layout__username-name {
  text-align: left;
  max-width: 600px;
}

.layout__menu-container,
.layout__menu {
  display: none;
}

@media screen and (max-width: 1120px) and (min-width: 840px) {
  .layout__support-container {
    display: none;
  }

  .layout__logo {
    left: 40px;
  }

  .layout__username {
    right: 32px;
  }
}


@media screen and (max-width: 1024px) and (min-width: 841px) {
  .layout__content-container {
    padding: 68px 0 38px 0;
  }

  .layout__header-content {
    display: none;
  }

  .layout__header {
    box-shadow: none;
    width: 800px;
    margin: 0 auto;
  }

  .layout__logo {
    left: 40px;
    pointer-events: none;

    & svg {
      width: 40px;
      height: 40px;
    }
  }

  .layout__username {
    display: none;
  }

  .layout__burger-menu {
    display: block;
    position: absolute;
    left: 0;
  }

  .layout__menu-container {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: none;

    &.open {
      z-index: 90;
      transform: translateY(0);
      background: rgba(0, 0, 0, 0.2);

      & ~ .layout__menu {
        transform: translateY(0);
      }
    }
  }

  .layout__menu {
    display: block;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    right: 0;
    padding: 19px 0 32px 0;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    transform: translateY(-100%);
    background: white;
    box-shadow: 4px 0 6px rgba(52, 55, 67, 0.12);
  }

  .layout__menu-close {
    margin-left: 16px;
  }

  .layout__menu-title {
    margin: 26px 0 30px 0;
    padding: 0 16px;
    color: $text-headers;
  }

  .layout__menu-item {
    display: block;
    width: 100%;
    text-align: left;
    padding: 20px 0;
    color: $text-headers;
    text-indent: 16px;
    border-bottom: 2px solid #EAEDF2;

    &.operator-account-item {
      border-bottom: none;
    }
  }

  .layout__menu-support {
    margin-top: 75px;
    padding: 0 16px;
  }

  .layout__support-container {
    display: none;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  html, body, #root {
    height: 100%;
  }

  .layout__content-container {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 68px 0 0 0;

    &.services__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: calc(100% - 76px);
      max-height: calc(100% - 76px);
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
    }

    &.website__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-height: calc(100% - 76px);
      max-height: calc(100% - 76px);
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
    }

    &:not(.error).unauthorized,
    &.centered-content {
      padding: 0 16px;
    }
  }

  .layout__content-container .login-page__content {
    width: 100%;
  }

  .layout__support-container {
    display: none;
  }

  .layout__header {
    box-shadow: none;
  }

  .layout__logo {
    left: 56px;
    pointer-events: none;

    & svg {
      width: 40px;
      height: 40px;
    }
  }

  .layout__username {
    display: none;
  }

  .layout__burger-menu {
    display: block;
    position: absolute;
    left: 16px;
  }

  .layout__menu-container {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: none;

    &.open {
      z-index: 90;
      transform: translateY(0);
      background: rgba(0, 0, 0, 0.2);

      & ~ .layout__menu {
        transform: translateY(0);
      }
    }
  }

  .layout__menu {
    display: block;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    right: 0;
    padding: 19px 0 32px 0;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    transform: translateY(-100%);
    background: white;
    box-shadow: 4px 0 6px rgba(52, 55, 67, 0.12);
  }

  .layout__menu-close {
    margin-left: 16px;
  }

  .layout__menu-title {
    margin: 26px 0 30px 0;
    padding: 0 16px;
    color: $text-headers;
  }

  .layout__menu-item {
    display: block;
    width: 100%;
    text-align: left;
    padding: 20px 0;
    color: $text-headers;
    text-indent: 16px;
    border-bottom: 2px solid #EAEDF2;

    &.operator-account-item {
      border-bottom: none;
    }
  }

  .layout__menu-support {
    margin-top: 75px;
    padding: 0 16px;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .layout__header-content {
    display: none;
  }
}


