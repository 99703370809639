@import '../../MainPage/style-guide.scss';


.locationsCategories__container{
  display: flex;
  align-self: stretch;
  background-color: #EAEDF2;
  padding: 80px 0;
  flex: 1;
}

.locationsCategories__content{
  flex: 10;
  display: flex;
  flex-direction: column;
}

.locationsCategories__navigation-links{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 30px;
  a{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    margin-right: 10px;
    color: #92A4B5 !important;
  }
  p{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    margin-left: 10px;
    color: #00BFB4;
  }
}

.locationsCategories__navigation-links_1024{
  display: none;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 30px;
  a{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #92A4B5 !important;
    white-space: nowrap;
  }
}

.locationsCategories__header{
  display: flex;
  font-size: 48px;
  font-family: 'Inter-Black';
  line-height: 61px;
  letter-spacing: 0.02em;
  color: #343743;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.locationsCategories__cards-page{
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  align-content: start;
  grid-gap: 10px;
  margin-top: 30px;
}

.locationsCategories__card-item {
    flex: 1;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: 8px;
    margin:0 10px 8px 0;
    transition: 300ms;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}

.locationsCategories__cards-item_bottom{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    align-self: stretch;
    height: 90px;
    max-height: 90px;
    background-color: $white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-left: 20px;
}

.locationsCategories__cards-item_title {
    font-family: 'Inter-SemiBold';
    color: $text-dark;
    font-size: 24px;
    letter-spacing: 0.015em;
    line-height: 29px;
}

@media screen and (max-width: 1366px) and(min-width: 1025px){
  .locationsCategories__cards-page{
    grid-template-columns: repeat(2, 1fr);
  }

  .locationsCategories__card-item-page{
    padding: 10px;
  }

  .locationsCategories__card-item-pictire{
    width: 133px;
    height: 135px;
    border-radius: 8px;
  }

  .locationsCategories__card-description-title {
    font-size: 16px;
    line-height: 26px;
  }

  .locationsCategories__card-description-text {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1024px) and(min-width: 801px){
  .locationsCategories__navigation-links{
    display: none;
  }

  .locationsCategories__navigation-links_1024{
    display: flex;
  }

  .locationsCategories__container{
    padding: 60px 0;
  }

  .locationsCategories__cards-page{
    grid-template-columns: repeat(2, 1fr);
  }

  .locationsCategories__card-item-page{
    padding: 10px;
  }

  .locationsCategories__card-item-pictire{
    width: 115px;
    height: 135px;
    border-radius: 8px;
  }

  .locationsCategories__card-description-title {
    font-size: 16px;
    line-height: 26px;
  }

  .locationsCategories__card-description-text {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 800px) and(min-width: 1px){
  .locationsCategories__navigation-links{
    display: none;
  }

  .locationsCategories__navigation-links_1024{
    display: flex;
  }

  .locationsCategories__container{
    padding: 60px 16px;
  }

  .locationsCategories__cards-page{
    grid-template-columns: minmax(0, 500px);
  }

  .locationsCategories__card-item-page{
    padding: 10px;
  }

  .locationsCategories__card-item-pictire{
    width: 115px;
    height: 135px;
    border-radius: 8px;
  }

  .locationsCategories__card-description-title {
    font-size: 16px;
    line-height: 26px;
  }

  .locationsCategories__card-description-text {
    font-size: 14px;
    line-height: 22px;
  }
}