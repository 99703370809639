@import '../../style-guide';

.register-provider__container {
  position: relative;
  background-color: $primary-accent;
  height: 259px;
  min-height: 259px;
  overflow-x: hidden;
}

.register-provider__content-container {
  position: relative;
  width: 1920px;
  height: 259px;
  margin: 0 auto;
}

.register-provider__text {
  width: 560px;
  padding-top: 59px;
  margin-left: 319px;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.015em;
  color: #FFFFFF;
}

.register-provider__plus-icon {
  position: absolute;
  top: 43px;
  left: 291px;
  width: 23px;
  height: 23px;
}

.register-provider__btn {
  position: absolute;
  top: 66px;
  left: 866px;
  background: #FFCB31;
  border-radius: 8px;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #343743;


  &:hover {
    background: #F5D53B;
  }

  &:active {
    background: #F9BF16;
  }
}

.register-provider__resorts {
  display: none;
  position: absolute;
  bottom: 0;
  left: 323px;
  height: 100px;
  // display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #FFFFFF;
}

.register-provider__resorts-line {
  width: 126px;
  border-top: 2px solid #FFCB31;

  &.edge {
    width: calc(100vw - 1283px);
  }

  &.before-line {
    position: absolute;
    left: calc(1283px - 100vw);
    width: calc(100vw - 1283px);
  }
}

@media screen and (max-width: 1919px) and (min-width: 1365px) {
  .register-provider__content-container {
    width: 1366px;
  }

  .register-provider__text {
    width: 560px;
    padding-top: 49px;
    margin-left: 135px;
  }

  .register-provider__plus-icon {
    position: absolute;
    top: 32px;
    left: 112px;
    width: 23px;
    height: 23px;
  }

  .register-provider__btn {
    position: absolute;
    top: 56px;
    left: 719px;
  }

  .register-provider__resorts {
    left: 135px;
    font-size: 18px;
    line-height: 22px;
    gap: 6px;
  }

  .register-provider__resorts-line {
    width: 108px;

    &.edge {
      width: calc(100vw - 1083px);
    }

    &.before-line {
      left: calc(1083px - 100vw);
      width: calc(100vw - 1083px);
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 1023px) {
  .register-provider__container {
    height: 209px;
    min-height: 209px;
  }

  .register-provider__content-container {
    width: 1024px;
    height: 209px;
  }

  .register-provider__text {
    font-size: 30px;
    line-height: 36px;
    width: 400px;
    padding-top: 58px;
    margin-left: 94px;
  }

  .register-provider__plus-icon {
    top: 32px;
    left: 72px;
    width: 23px;
    height: 23px;
  }

  .register-provider__btn {
    position: absolute;
    top: 56px;
    left: 647px;
  }

  .register-provider__resorts {
    font-size: 16px;
    line-height: 19px;
    left: 94px;
    height: 52px;
    gap: 3px;
  }

  .register-provider__resorts-line {
    width: 51px;

    &.edge {
      width: calc(100vw - 800px);
    }

    &.before-line {
      left: calc(800px - 100vw);
      width: calc(100vw - 800px);
    }
  }
}

@media screen and (max-width: 1023px) and (min-width: 375px) {
  .register-provider__container {
    height: 195px;
    min-height: 195px;
  }

  .register-provider__content-container {
    width: 375px;
    height: 195px;
  }

  .register-provider__text {
    font-size: 20px;
    line-height: 24px;
    width: 200px;
    padding-top: 40px;
    margin-left: 30px;
  }

  .register-provider__plus-icon {
    top: 10px;
    left: 4px;
    width: 23px;
    height: 23px;
  }

  .register-provider__btn {
    position: absolute;
    top: 125px;
    left: 30px;
  }

  .register-provider__resorts {
    display: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 320px) {
  .register-provider__container {
    height: 195px;
    min-height: 195px;
  }

  .register-provider__content-container {
    width: 100%;
    height: 195px;
  }

  .register-provider__text {
    font-size: 20px;
    line-height: 24px;
    width: 200px;
    padding-top: 40px;
    margin-left: 30px;
  }

  .register-provider__plus-icon {
    top: 10px;
    left: 4px;
    width: 23px;
    height: 23px;
  }

  .register-provider__btn {
    position: absolute;
    top: 125px;
    left: 30px;
  }

  .register-provider__resorts {
    display: none;
  }
}
