@import '../../pages/style-guide';

.images-area__container {
  width: 100%;
  margin: 0 0 44px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  &.single {
    flex-direction: row;

    & .images-area__dropzone {
      width: 680px;
      padding: 38px 116px;
    }

    &.audio {
      & .images-area__dropzone {
        padding: 25px 166px;
      }
    }
  }
}

.images-area__dropzone {
  padding: 38px 87px;
  background-color: $background-grey-normal;
  box-sizing: border-box;
  border-radius: 8px;
  color: $text-normal;
  text-align: center;
  cursor: pointer;

  &.dragging {
    background-color: #F2F2F4;
  }

  &.error {
    border: 1px solid #D63854;
  }
}

.images-area__dropzone-button {
  display: inline-block;
  color: $primary-accent;
}

.images-area__photos {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.images-area__photos-item-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
}

.images-area__photos-item {
  position: absolute;
  height: 100%;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.images-area__photos-item-close-icon {
  position: absolute;
  top: 7px;
  right: 7px;
  border-radius: 50%;
  background-color: #F2F2F4;

  &:hover {
    background-color: #d0d0d0;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .images-area__container {
    &.single {
      & .images-area__dropzone {
        width: 100%;
        padding: 12px 24px;
      }

      &.audio {
        & .images-area__dropzone {
          padding: 12px 24px;
        }
      }
    }
  }
  .images-area__dropzone {
    padding: 12px 24px;
    width: calc(100% - 32px);
    margin: 0 16px;
  }

  .images-area__photos {
    padding: 0 16px;
  }

  .images-area__dropzone-new-line {
    display: none;
  }
}
