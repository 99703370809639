@import '../../style-guide.scss';

.reviews__rating-container {
  display: flex;
  align-items: center;
  margin: 40px 0 32px 0;
  gap: 6px;
  color: $primary-accent;
}

.reviews__rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 7px 6px;
  color: $text-dark-background;
  background-color: $primary-accent;
  border-radius: 4px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .reviews__rating-container {
    margin-left: 16px;
    margin-top: 20px;
  }
}