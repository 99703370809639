@import '../../../style-guide';

.edit-service__title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 40px 0;
}

.edit-service__content {
  width: 528px;
}

.edit-service__title {
  margin-bottom: 20px;
}

.edit-service__title-input {
  display: inline-block;
  min-width: 332px;
  max-width: 100%;

  &.instructor-name {
    width: 148px;
    min-width: 148px;
    max-width: 148px;
    margin-right: 6px;
  }

  &.instructor-surname {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
  }
}

.edit-service__children-title,
.edit-service__category-title {
  margin: 20px 0 20px 0;
}

.edit-service__images-title,
.edit-service__desc-title {
  margin: 40px 0 20px 0;
}

.edit-service__duration-title {
  margin: 36px 0 28px 0;
}

.edit-service__children {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}

.edit-service__child-option-wrapper {
  width: 184px;
}

.edit-service__delivery-option-wrapper {
  width: 220px;
}

.edit-service__all-day-option-wrapper {
  width: 356px;

  & .edit-service__child-option {
    color: $text-headers;
  }
}

.edit-service__child-icon {
  margin-right: 7px;
}

.edit-service__child-option {
  color: $text-headers;
}

.edit-service__child-switch {
  margin-right: 128px;
}

.edit-service__cost-from {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 175px;
  color: $text-labels;
}

.edit-service__cost-from-input {
  padding: 8px 32px 8px 12px;
}

.edit-service__rub-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.edit-service__rub-unit {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #92A4B5;
}

.edit-service__amount {
  width: 142px;
  min-width: 142px;
  max-width: 142px;
}

.edit-service__duration {
  display: flex;
  width: 184px;
  gap: 6px;
}

.edit-service__select {
  width: 332px;
}

.edit-info__item {
  margin-bottom: 44px;
}

.edit-service__buttons {
  display: flex;
  gap: 24px;
  margin-top: 32px;
}

.edit-service__cancel-button {
  width: 124px;
}

.edit-info__location-item {
  display: flex;
  gap: 8px;
}

.edit-service__loader {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.time-slot__container.edit-info__time-container {
  width: 90px;
  justify-content: space-around;

  &.error {
    border: 1px solid $input-warning-error;
  }
}

.edit-info__time-label {
  margin: 0 0 4px 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  color: $text-labels;
}

.edit-service__sub-category-title {
  &.error {
    color: $input-warning-error;
  }
}

.edit-service__save-button {
  width: 220px;
}

.step1__next-btn {
  width: 220px;
  margin: 44px 0 0 0;
}

.step1__tag {
  margin-bottom: 20px;
}

.view-info__item-title.step1__header2 {
  margin-bottom: 12px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .edit-service__title-row {
    padding: 18px 16px 20px 16px;
    box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
    width: 100%;
    margin: 0;
  }

  .edit-service__images-title {
    margin: 22px 0 20px 0;
  }

  .edit-service__content {
    width: 100%;
    padding: 0 16px;
  }

  .edit-service__select {
    width: 100%;
  }

  .view-info__item-title.edit-service__category-title,
  .view-info__item-title.edit-service__children-title,
  .view-info__item-title.edit-service__desc-title {
    padding-left: 0;

    &.edit-service__location-title,
    &.edit-service__amount-title,
    &.edit-service__hours-title,
    &.detailed-cost-title {
      padding-left: 16px;
    }
  }

  .edit-service__title-input {
    min-width: 100%;
    max-width: 100%;
  }

  .edit-service__child-option {
    width: 184px;

    &.all-day-option {
      width: 242px;
    }
  }

  .edit-service__amount {
    padding-left: 16px;

    &.max-amount {
      padding-left: 0;
    }
  }

  .edit-service__buttons {
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 16px;
  }

  .edit-service__cancel-button {
    width: 100%;
  }

  .edit-service__duration {
    padding: 0 16px;
  }

  .edit-service__title-input.instructor-name,
  .edit-service__title-input.instructor-surname {
    min-width: 100%;
    max-width: 100%;
  }

  .edit-service__title-input.instructor-name {
    margin-bottom: 16px;
  }

  .edit-info__item .sub-categories__container {
    padding: 0 16px;
  }

  .edit-service__save-button,
  .edit-service__cancel-button {
    width: 100%;
  }

  .edit-service__instruction-link {
    margin: 16px 16px 0 16px;
  }

  .step1__next-btn {
    width: 100%;
  }
}


@media screen and (max-width: 374px) and (min-width: 320px) {
  .edit-service__child-option {
    &.all-day-option {
      width: 180px;
    }
  }
}
