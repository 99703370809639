@import '../../pages/style-guide';

.input__container {
  position: relative;
  width: 100%;
  padding: 7px 12px;
  background-color: $background-grey-normal;
  border-radius: 8px;
  border: 1px solid $background-grey-normal;

  &.error:not(.focus) {
    border: 1px solid $input-warning-error;
  }

  &.disabled {
    background-color: white;
    border: 2px solid $background-grey-normal;
  }

  &:not(.disabled):hover {
    background-color: $background-grey-hover;

    & .input {
      background-color: $background-grey-hover;

      &:-webkit-autofill  {
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px $background-grey-hover;
      }
    }
  }

  &.focus {
    box-shadow: 0 0 0 2px $input-active-stroke;
  }
}

.input {
  width: 100%;
  color: $text-normal;
  background-color: $background-grey-normal;
  font-family: 'Inter', sans-serif;

  &::placeholder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: $text-labels;
  }

  &:-webkit-autofill {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px $background-grey-normal;
  }

  &:disabled {
    background-color: white;
  }
}

.input-label {
  display: block;
  margin-bottom: 4px;
  color: $text-labels;
  text-align: left;
}
