.sub-categories__container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  row-gap: 12px;
}

.sub-category__container {
  position: relative;
  min-width: 150px;

  &.sublocations {
    min-width: 200px;
  }
}

.sub-category__delete-btn {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

@media screen and (max-width: 840px) and (min-width: 320px) {

  .sub-categories__add-btn.edit-slot__add-time-btn {
    margin: 0;
  }
}
