@import '../style-guide.scss';

.category__container {
    background-color: $white;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 80px 0 80px 0;
    transition: 300ms;
}

.category__content {
    flex: 10;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.category__list {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    align-self: stretch;
    transition: 300ms;
}

.category__list-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 340px;
    font-size: 24px;
    max-width: 250px;
    transition: 300ms;
}

.category__list-item__top{
    margin-top: 10px;
    transition: 300ms;
}

.category__item-main-text {
    color: $background-dark-grey;
    font-family: 'Inter-SemiBold';
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 0.015em;
    text-align: center;
    transition: 300ms;
}

.category__list-item__bottom{
    margin-top: 10px;
    transition: 300ms;
}

.category__item-secondary-text {
    color: #5C6E7F;
    font-family: 'Inter-SemiBold';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
    transition: 300ms;
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
    .category__container {
        padding: 60px 0;
    }

    .category__list {
        margin-top: 35px;
    }

    .category__list-item {
        width: auto;
    }

    .category__item-main-text {
        font-size: 18px;
        line-height: 29px;
    }

    .category__item-secondary-text {
        font-size: 16px;
        line-height: 26px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 801px) {
    .category__container {
        padding: 60px 0;
    }

    .category__list {
        margin-top: 35px;
    }

    .category__list-item {
        width: auto;
    }

    .category__item-main-text {
        font-size: 18px;
        line-height: 29px;
    }

    .category__item-secondary-text {
        font-size: 16px;
        line-height: 26px;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px){
    .category__container {
        padding: 60px 16px;
    }

    .category__list {
        display: flex;
        margin-top: 35px;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-direction: column;
    }

    .category__list-item {
        margin-bottom: 30px;
        width: auto;
    }

    .category__list-item__top{
        margin-top: 5px;
    }

    .category__item-main-text {
        font-size: 18px;
        line-height: 29px;
    }

    .category__list-item__bottom{
        margin-top: 5px;
    }

    .category__item-secondary-text {
        font-size: 16px;
        line-height: 26px;
    }
}

@media screen and (max-width: 375px){
    .category__container {
        padding: 60px 16px;
    }

    .category__list {
        display: flex;
        margin-top: 35px;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-direction: column;
    }

    .category__list-item {
        margin-bottom: 30px;
        width: auto;
    }

    .category__list-item__top{
        margin-top: 5px;
    }

    .category__item-main-text {
        font-size: 18px;
        line-height: 29px;
    }

    .category__list-item__bottom{
        margin-top: 5px;
    }

    .category__item-secondary-text {
        font-size: 16px;
        line-height: 26px;
    }
}