@import '../../style-guide';

.provider__container {
  width: 100%;
  padding: 20px 24px;
  border: 2px solid $background-grey-normal;
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    background: $background-white-hover;
  }

  &:active {
    background: $background-white-pressed;
  }
}

.provider__name-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $text-headers;
}

.provider__status {
  padding: 4px 12px;
  color: $text-normal;
  background: $background-grey-normal;
  border-radius: 4px;
}

.provider__details-row {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.provider__details-number {
  margin: 0 4px;
  color: $text-normal;
}

.provider__details-new-number {
  padding: 2px 12px;
  color: $text-normal;
  background: $background-grey-normal;
  border-radius: 10px;
}

.provider__details-column {
  display: flex;
  align-items: center;
  height: 26px;

  &:not(:last-child) {
    margin-right: 36px;
  }
}

.provider__name-row-name {
  text-align: left;
  max-width: 550px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .provider__container {
    padding: 16px;
    margin-bottom: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid $background-grey-normal;
    border-radius: 0;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
