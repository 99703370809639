@import '../../style-guide.scss';

.view-info__content {
  margin-top: 60px;
}

.view-info__edit-button {
  width: 236px;
}

.view-info__item-title {
  margin-bottom: 20px;
  color: $text-headers;

  &.error {
    color: $input-warning-error;
  }
}

.view-info__item {
  max-width: 450px;
  margin-bottom: 44px;
  color: $text-normal;
  white-space: pre-wrap;

  &.full-width {
    max-width: 100%;
  }

  &.with-icon {
    display: inline-block;
  }

  &.category-item {
    margin-bottom: 20px;

    &.without-subcategory {
      margin-bottom: 44px;
    }
  }

  &.sub-category-item {
    margin-bottom: 0;
  }

  &.location-item {
    display: flex;
    gap: 8px;
  }
}

.view-info__with-icon-item {
  display: flex;
  align-items: center;
  min-width: 62px;
  padding: 6px 12px 6px 6px;
  background: $background-grey-normal;
  border-radius: 4px;
  color: $text-labels;
}

.view-info__item-icon {
  margin-right: 7px;
}

.view-info__decline-changes-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 9px 70px;
  color: $text-headers;
  background-color: $background-grey-normal;
  border-radius: 8px;
}

.view-info__approve-btn {
  margin: 0 14px 0 36px;
  background-color: $primary-accent-pressed;
}

.view-info__decline-btn {
  margin: 0 14px 0 36px;
  background-color: $input-warning-error;
}

.view-info__decline-changes-panel-btn {
  width: 180px;
}

.view-info__subcategories {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 44px;
}

.leaflet-control-attribution {
  color: $text-normal;

  & a {
    color: $primary-accent;
  }
}

.meeting-point__item {
  margin-bottom: 44px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .view-info__content {
    margin-top: 20px;
  }

  .view-info__item-title {
    padding-left: 16px;
  }

  .view-info__item {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 32px;

    &.sub-category-item {
      padding: 0;
    }
  }

  .view-info__item.category-item.without-subcategory {
    margin-bottom: 32px;
  }

  .view-info__edit-button {
    width: calc(100% - 32px);
    margin: 0 16px 16px 16px;
  }

  .view-info__decline-changes-panel {
    width: 100%;
    margin-top: 16px;
    padding: 12px 0;
    border-radius: 0;
    flex-direction: column;
    gap: 16px;
  }

  .view-info__decline-changes-panel-btn {
    width: calc(100% - 32px);
    margin: 0 16px;
  }

  .view-info__subcategories {
    padding: 0 16px;
  }

  .meeting-point__item {
    margin-bottom: 32px;
  }
}
