@import '../style-guide.scss';

.cardlist__slider {
    width: 187px;
    height: 8px;
    background: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
}

.cardlist__container {
    width: 100%;
    background-color: $background-dark-grey;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 80px 0 60px 0;
    transition: 300ms;
}

.cardlist__content {
    flex: 12;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    min-width: 0;
    transition: 300ms;
}

.cardlist__cards {
    display: flex;
    align-self: stretch;
    width: 100%;
    margin-top: 40px;
    overflow-x: auto;
    overflow-y: hidden;
}

// .cardlist__cards::-webkit-scrollbar {
//     // display: none;
// }

.cardlist__cards::-webkit-scrollbar {
    //display: none;
    width: 12px;
    height: 16px;
    border: 0.5px solid $background-dark-grey;
}

.cardlist__cards::-webkit-scrollbar-track:horizontal {
    display: none;
    width: 16px;
    height: 16px;
    background-color: $background-dark-grey;
}

.cardlist__cards::-webkit-scrollbar-thumb:horizontal {
    background-color: #ddd;
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 8px;
}

// /* Track */
// .cardlist__cards::-webkit-scrollbar-track {
//     background: #343743;
// }

// /* Handle */
// .cardlist__cards::-webkit-scrollbar-thumb {
//     background-color: #fff;
//     border: 20px solid transparent;
//     border-radius: 80px;
//     height: 10px;
//     background-clip: content-box;
// }

.cardlist__single-card{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 340px;
    min-width: 340px;
    height: 470px;
    border-radius: 8px;
    margin-right: 20px;
    transition: 300ms;
}

.cardlist__single-card-footer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-self: stretch;
    background-color: $white;
    padding: 5px 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 110px;
    transition: 300ms;
}

.cardlist__single-card-title {
    font-family: 'Inter-SemiBold';
    font-size: 24px;
    line-height: 36px;
    color: #343743;
    text-transform: uppercase;
    transition: 300ms;
    text-align: start;
}

.cardlist__single-card-text {
    font-family: 'Inter-Regular';
    font-size: 20px;
    line-height: 30px;
    color: #A6A6A6;
    transition: 300ms;
}

.cardlist__button-container{
    flex: 9;
    display: flex;
    justify-content: flex-end;
}

.cardlist__wrapper-button {
    flex: 10;
    display: flex;
    justify-content: flex-end;
    align-self: stretch;
    margin-top: 40px;
}

.cardlist__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 410px;
    height: 56px;
    background-color: #FFCB31;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px;
    transition: 300ms;
}

.cardlist__button-text {
    color: $background-dark-grey;
    font-family: 'Inter-SemiBold';
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.02em;
}

@media screen and (max-width: 1366px) and (min-width: 1025px){
    .cardlist__single-card{
        width: 260px;
        height: 405px;
        min-width: 260px;
        margin-right: 10px;
    }
    
    .cardlist__button {
        width: 340px;
        height: 47px;
        padding: 10px;
    }

    .cardlist__button-text {
        font-size: 18px;
        line-height: 27px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 801px){
    .cardlist__container {
        padding: 60px 0;
    }
    
    .cardlist__single-card{
        width: 200px;
        height: 270px;
        min-width: 200px;
        margin-right: 10px;
    }
    
    .cardlist__single-card-footer {
        height: 80px;
    }
    
    .cardlist__single-card-title {
        font-size: 16px;
        line-height: 24px;
    }

    .cardlist__single-card-text {
        font-size: 14px;
        line-height: 21px;
    }
    
    .cardlist__wrapper-button {
        flex: 10;
    }
    
    .cardlist__button {
        width: 290px;
        height: 44px;
    }

    .cardlist__button-text {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px){
    .cardlist__container {
        padding: 60px 0 60px 16px;
    }
    
    .cardlist__single-card{
        width: 165px;
        height: 250px;
        min-width: 165px;
        margin-right: 8px;
    }
    
    .cardlist__single-card-footer {
        height: 80px;
    }
    
    .cardlist__single-card-title {
        font-size: 16px;
        line-height: 24px;
    }

    .cardlist__single-card-text {
        font-size: 14px;
        line-height: 21px;
    }
    
    .cardlist__wrapper-button {
        padding-right: 16px;
    }
    
    .cardlist__button {
        width: 290px;
        height: 44px;
    }

    .cardlist__button-text {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 375px){
    .cardlist__container {
        padding: 60px 0 60px 16px;
    }
    
    .cardlist__single-card{
        width: 165px;
        height: 250px;
        min-width: 165px;
        margin-right: 8px;
    }
    
    .cardlist__single-card-footer {
        height: 80px;
    }
    
    .cardlist__single-card-title {
        font-size: 16px;
        line-height: 24px;
    }

    .cardlist__single-card-text {
        font-size: 14px;
        line-height: 21px;
    }
    
    .cardlist__wrapper-button {
        padding-right: 16px;
    }
    
    .cardlist__button {
        width: 290px;
        height: 44px;
    }

    .cardlist__button-text {
        font-size: 16px;
        line-height: 24px;
    }
}