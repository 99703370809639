@import 'webfonts/RobotoFonts.css';
@import 'webfonts/InterFonts.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  caret-color: #00A196;
}

body,
html {
  margin: 0;
  font-family: 'Inter';
}

input {
  border: none;
  outline: none;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

div[role='button']:focus-visible {
  outline: none;
}

div:focus-visible,
canvas:focus-visible {
  outline: none;
}

button {
  -webkit-tap-highlight-color: transparent;
}
