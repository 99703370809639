@import '../../../../pages/style-guide.scss';

.popup-menu__container {
  position: relative;
  margin-top: 2px;
  padding: 1px 0;
}

.popup-menu__menu-button {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  border-radius: 50%;

  &.active {

  }
}

.popup-menu__list-item {
  width: 100%;
  padding: 8px 0;
  color: $button-normal;
  text-indent: 16px;
  text-align: left;
  font-family: 'Inter', sans-serif;

  &:not(:disabled):hover {
    background: $background-white-hover;
  }

  &:active {
    background: $background-white-pressed;
  }

  &:not(:last-child) {
    border-bottom: 2px solid $background-grey-normal;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:disabled {
    color: $text-labels;
    cursor: default;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .popup-menu__container {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 1001;
  }
}
