@import '../style-guide.scss';   

.usefull__card-item:nth-of-type(1n+4){
    display: none;
}

.usefull__container {
    width: 100%;
    background-color: $background-light-grey;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 80px 0 70px 0;
    transition: 300ms;
}

.usefull__content {
    flex: 10;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    transition: 300ms;
}

.usefull__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
    transition: 300ms;
}

.usefull__card-item {
    display: flex;
    height: 207px;
    padding: 20px;
    background-color: $white;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 4px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
    transition: 300ms;
}

.usefull__picture-container{
    flex: 1;
    transition: 300ms;
}

.usefull__picture {
    width: 160px;
    height: 170px;
    border-radius: 4px;
    transition: 300ms;
}

.usefull__card-description{
    flex: 3;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    align-self: stretch;
    margin-left: 10px;
    transition: 300ms;
}

.usefull__card-description-title_container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 300ms;
}

.usefull__card-description-title {
    font-family: 'Inter-SemiBold';
    font-size: 20px;
    line-height: 32px;
    color: $black-amber;
    text-align: start;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: 300ms;
}

.usefull__card-description-text_container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    transition: 300ms;
}

.usefull__card-description-text {
    color: $black-amber;
    font-family: 'Inter-Regular';
    font-size: 18px;
    line-height: 29px;
    text-align: start;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    opacity: 0.6;
    transition: 300ms;
}

.usefull__links {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    transition: 300ms;
}

.usefull__link {
    font-family: 'Inter-Medium';
    color: $background-dark-grey;
    font-size: 24px;
    line-height: 36px;
    margin-right: 10px;
    transition: 300ms;
}

.usefull__link-arrow{
    display: flex;
    align-items: center;
    transition: 300ms;
}

@media screen and (max-width: 1366px) and (min-width: 1025px){
    .usefull__container {
        padding: 60px 0;
    }

    .usefull__cards {
        margin-top: 10px;
    }

    .usefull__card-item {
        height: 165px;
        padding: 10px;
    }

    .usefull__picture {
        width: 133px;
        height: 135px;
    }

    .usefull__card-description-title {
        font-size: 16px;
        line-height: 26px;
    }

    .usefull__card-description-text {
        font-size: 14px;
        line-height: 22px;
    }

    .usefull__link {
        font-size: 18px;
        line-height: 27px;
    }
}
    

@media screen and (max-width: 1024px) and (min-width: 801px){
    .usefull__card-item:nth-of-type(1n+3){
        display: none;
    }

    .usefull__container {
        padding: 60px 0;
    }

    .usefull__cards {
        margin-top: 10px;
        grid-template-columns: repeat(2, 1fr);
    }

    .usefull__card-item {
        height: 165px;
        padding: 10px;
    }

    .usefull__picture {
        width: 133px;
        height: 135px;
    }

    .usefull__card-description-title {
        font-size: 16px;
        line-height: 26px;
    }

    .usefull__card-description-text {
        font-size: 14px;
        line-height: 22px;
    }

    .usefull__link {
        font-size: 18px;
        line-height: 27px;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px){
    .usefull__card-item:nth-of-type(1n+4){
        display: none;
    }

    .usefull__container {
        padding: 60px 16px;
    }

    .usefull__cards {
        margin-top: 30px;
        grid-template-columns: minmax(340px, 410px);
        grid-auto-rows: 165px;
    }

    .usefull__card-item {
        height: 165px;
        padding: 10px;
    }

    .usefull__picture {
        width: 115px;
        height: 137px;
    }

    .usefull__card-description-title {
        font-size: 16px;
        line-height: 26px;
    }

    .usefull__card-description-text {
        font-size: 14px;
        line-height: 22px;
    }

    .usefull__link {
        font-size: 18px;
        line-height: 27px;
    }
}

@media screen and (max-width: 375px){
    .usefull__card-item:nth-of-type(1n+4){
        display: none;
    }

    .usefull__container {
        padding: 60px 16px;
    }

    .usefull__cards {
        margin-top: 30px;
        grid-template-columns: minmax(340px, 410px);
        grid-auto-rows: 165px;
    }

    .usefull__card-item {
        height: 165px;
        padding: 10px;
    }

    .usefull__picture {
        width: 115px;
        height: 137px;
    }

    .usefull__card-description-title {
        font-size: 16px;
        line-height: 26px;
    }

    .usefull__card-description-text {
        font-size: 14px;
        line-height: 22px;
    }

    .usefull__link {
        font-size: 18px;
        line-height: 27px;
    }
}
