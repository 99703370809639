@import '../style-guide.scss';

.edit-slot__calendar-container {
  position: absolute;
  width: 370px;
  margin-top: 8px;
  z-index: 100;
  box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
  border-radius: 8px;
}

.edit-slot__date-picker {
  display: flex;
  flex-direction: column;
  gap: 4px;

  & .date-picker__calendar-icon path {
    fill: $icon-light-background;
  }
}

.edit-slot__time-pickers-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 24px;
}

.edit-slot__weekdays-picker {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 24px;
}

.edit-slot__time-pickers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: flex-start;
}

.time-slot__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 108px;
  height: 44px;
  padding: 0 10px;
  background: $background-grey-normal;
  border-radius: 4px;

  &.error {
    border: 1px solid $input-warning-error;
  }
}

.time-slot__picker {

  & .react-time-picker__wrapper {
    border: none;
  }

  & .react-time-picker__inputGroup {
    min-width: calc((4px * 3) + 0.217em * 2);
    display: flex;
    align-items: center;
  }

  & .react-time-picker__inputGroup__input,
  & .react-time-picker__inputGroup__divider,
  & .react-time-picker__inputGroup__leadingZero {
    color: $text-normal;
    line-height: 26px;
    height: unset;
  }
}

.edit-slot__add-time-btn {
  width: 150px;
  margin-top: 2px;

  & .services__add-btn-icon path {
    fill: $button-normal;
  }
}

.time-slot__delete-time-btn {
  width: 24px;
  height: 24px;

  & path {
   fill: $text-headers;
  }
}

.edit-slot__slots-list-title {
  margin: 44px 0 24px 0;
  color: $text-headers;
}

.edit-slot__table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  color: $text-normal;

  & thead {
    background: $background-grey-normal;
    color: $icon-inactive;
  }

  & td{
    width: 33.3%;
    padding: 7px 12px;
    border-top: 2px solid $background-grey-normal;
    border-right: 2px solid $background-grey-normal;
  }

  &.thead-table td{
    width: 33.3%;
    padding: 7px 12px;
    border-right: 2px solid $background-grey-normal;
    border-top: 2px solid $background-grey-normal;
  }

  & tbody tr td:last-child {
    border-right: none;
  }

  & tbody:last-child tr:last-child td:first-child {

  }

  & tbody:last-child tr:last-child td:last-child {

  }

  & thead:first-child tr:first-child td:first-child {
    border-top-left-radius: 4px;
    border-left: 2px solid $background-grey-normal;
    border-right: none;
  }

  & thead:first-child tr:first-child td:nth-child(2) {
    border-left: 2px solid $background-grey-normal;
  }

  & thead:first-child tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }
}

.edit-slot__table-separator {
  height: 14px;
  background: $background-grey-normal;

  & td {
    padding: 0;
  }
}

.edit-slot__bottom-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}

.edit-slot__buttons {
  display: flex;
  gap: 8px;
}

.edit-slot__btn {
  width: 180px;
}

.edit-slot__label {
  min-width: 96px;
  color: $text-labels;

  &.time-label {
    margin-top: 13px;
  }

  &.error {
    color: $input-warning-error;
  }
}

.edit-slot__delete-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: $input-warning-error;

  &:disabled {
    color: $icon-inactive;
    cursor: default;

    & svg path {
      stroke: $icon-inactive;
    }
  }
}

.edit-slot__hint-message {
  margin-top: 6px;
  color: $text-labels;
}


@media screen and (max-width: 840px) and (min-width: 320px) {
  .edit-slot__slots-list-title {
    padding: 0 16px;
  }

  .edit-slot__table td,
  .edit-slot__table.thead-table td {
    width: unset;
    text-align: center;
    padding: 7px 4px;
  }

  .edit-slot__table .edit-slot__table-time-td,
  .edit-slot__table.thead-table .edit-slot__table-time-td {
    width: 22%;
  }

  .edit-slot__table .edit-slot__table-weekday-td,
  .edit-slot__table.thead-table .edit-slot__table-weekday-td {
    width: 37%;
  }

  .edit-slot__table {
    & thead:first-child tr:first-child td:first-child {
      border-top-left-radius: 0;
    }

    & thead:first-child tr:first-child td:last-child {
      border-top-right-radius: 0;
    }
  }
}

.edit-slot__bottom-row-xs {
  display: none;
}

.edit-slot__errors-xs {
  display: none;
}

.edit-slot__errors,
.edit-slot__errors-xs {
  margin-top: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: $input-warning-error;
}

.scroll-wrapper {
  max-height: 310px;
  overflow-y: overlay;
  overscroll-behavior: auto;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom: 2px solid $background-grey-normal;
  border-left: 2px solid $background-grey-normal;
  border-right: 2px solid $background-grey-normal;
}

.scroll-wrapper::-webkit-scrollbar {
  background: transparent;
  width: 14px;
}

.scroll-wrapper::-webkit-scrollbar-track {
  display: none;
}

.scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: $background-grey-pressed;
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 14px;
  min-height: 30px;
}

.infinite-scroll__loader {
  width: 100%;
  padding: 7px 0;
  text-align: center;
  border-top: 2px solid $background-grey-normal;
  color: $text-normal;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .edit-slot__date-picker {
    margin-top: 20px;
    padding: 0 16px;
  }

  .edit-slot__calendar-container {
    width: calc(100% - 32px);
    max-width: 370px;
  }

  .edit-slot__label.time-label {
    padding: 0 16px;
  }

  .edit-slot__time-pickers {
    padding: 0 16px;
  }

  .edit-slot__add-time-btn {
    margin: 2px 16px 0 16px;
  }

  .edit-slot__hint-message {
    margin: 6px 16px 0 16px;
  }

  .edit-slot__weekdays-picker {
    padding: 0 16px;
  }

  .edit-slot__bottom-row {
    display: none;
  }

  .edit-slot__bottom-row-xs {
    display: flex;
    width: 100%;
    padding: 0 16px;
    flex-direction: column;

    .edit-slot__buttons {
      margin-top: 20px;
      flex-wrap: wrap;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  .edit-slot__btn {
    width: 100%;
  }

  .edit-slot__delete-row {
    margin-top: 20px;
  }

  .edit-slot__table-wrapper:not(.all-slots-table-wrapper) {
    margin-bottom: 16px;
  }

  .edit-slot__errors {
    display: none;
  }

  .edit-slot__errors-xs {
    display: flex;
  }

  .scroll-wrapper {
    border-left: none;
    border-right: none;
  }
}
