.all-providers__controls-row {
  display: flex;
  margin-top: 35px;
  gap: 12px;
}

.all-providers__search-input-wrapper {
  width: 280px;
}

.all-providers__add-btn {
  width: 148px;
}

.all-providers__add-btn-icon {
  margin-right: 8px;
}

.all-providers__provider-info {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin: 36px 0 54px 0;
  padding: 15px 20px;
  background: #F2F2F4;
  border-radius: 8px;
}

.all-providers__provider-info-item-title {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  color: #323232;
}

.all-providers__provider-info-item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #99999B;
}

.all-providers__location-select {
  width: 232px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .all-providers__content .all-providers__provider-info {
    border-radius: 0;
  }

  .all-providers__controls-row {
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding-left: 16px;
    gap: 12px;
  }

  .all-providers__location-select {
    width: 280px;
  }
}
