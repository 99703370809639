.discounts__container {
  margin-top: 24px;
  padding: 20px 16px;
  border-radius: 8px;
  border: 2px solid #EAEDF2;
}

.discounts__sum-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.discounts__sum-title {
  width: 202px;
  color: #5C6E7F;
}

.discounts__discount-select {
  width: 142px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .discounts__container {
    margin: 24px 16px 0 16px;
    padding: 12px;
  }

  .discounts__sum-container {
    align-items: flex-start;
    flex-direction: column;
  }

  .discounts__new-line {
    display: none;
  }

  .prices__row-title.discounts__row-title {
    width: 250px;
  }
}