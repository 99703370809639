@import '../style-guide';

.booking-page__title-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.booking-page__statuses_wrapper {
  display: flex;
  gap: 6px;
}

.booking-page__status {
  margin: 0;
}

.booking-page__details {
  margin-top: 40px;
}

.booking-page__details-title {
  margin-bottom: 20px;
  color: $text-headers;
}

.booking-page__details-value {
  color: $text-normal;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.booking-page__cost-field {
  color: $text-labels;

  & .booking-page__cost-field-value {
    color: $text-normal;

    & .paid {
      color: $primary-accent;
    }

    & .refund {
      color: $input-warning-error;
    }
  }

  &.full-cost {
    margin-bottom: 8px;
  }

  &.service-charge {
    margin-bottom: 20px;
  }

  &.customer-payment {
    padding-top: 20px;
    margin-bottom: 44px;
    border-top: 2px solid $background-grey-normal;
  }
}

.booking-page__details-link {
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  & .booking-page__details-value {
    color: $primary-accent;
  }
}

.booking-page__buttons {
  margin-top: 44px;
  display: flex;

  &.small-margin {
    margin-top: 12px;
  }
}

.booking-page__button {
  width: 180px;

  &.approve-btn {
    margin-right: 12px;
  }

  &.booking-page__not-provided-button {
    width: 208px;
  }
}

.booking-page__button-icon {
  margin-right: 8px;
}

.booking-page__cancel-booking-btn {
  width: 350px;
}

.booking-page__review-container {
  width: 498px;
  padding: 24px;
  margin-bottom: 32px;
  border-radius: 8px;
  border: 2px solid $background-grey-normal;

  &.refund-container {
    width: 551px;
  }
}

.booking-page__review-comment {
  margin: 20px 0;
  color: $text-normal;

  &:last-child {
    margin-bottom: 0;
  }
}

.booking-page__review-buttons {
  display: flex;
}

.booking-page__notification {
  width: 412px;
  padding: 12px;
  background: $background-grey-normal;
  border-radius: 8px;
  color: $text-labels;
}

.booking-page__refund-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 343px;
}

.booking-page__refund-form-button-wrapper {
  display: block;
  width: 100%;

  & button {
    width: 100%;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .booking-page__title {
    padding: 18px 16px 14px 16px;
    box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
    width: 100%;
    margin: 0;
  }

  .booking-page__statuses_wrapper {
    flex-wrap: wrap;
    margin: 14px 16px 0 16px;
  }

  .booking-page__details {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .booking-page__details-title,
  .booking-page__details-value {
    padding: 0 16px;
    text-align: left;
  }

  .booking-page__cost-field {
    padding: 0 16px;

    &.customer-payment {
      max-width: unset;
      width: calc(100% - 32px);
      padding: 20px 0 0 0;
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .booking-page__buttons {
    width: 100%;
    padding: 0 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    flex-wrap: wrap;
    gap: 20px;

    &.small-margin {
      margin-top: 20px;
    }
  }

  .booking-page__button {
    width: 100%;

    &.approve-btn {
      margin-right: 0;
    }

    &.booking-page__not-provided-button {
      width: 100%;
    }
  }

  .booking__comment-title {
    padding: 0;
  }

  .booking-page__review-container {
    margin: 0 16px 32px 16px;
    width: calc(100% - 32px);
    max-width: 400px;

    &.refund-container {
      width: calc(100% - 32px);
      max-width: 551px;

      & .booking-page__details-title {
        padding: 0;
      }
    }
  }

  .booking-page__review-buttons {
    flex-direction: column;
    gap: 20px;
  }

  .booking-page__notification {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .booking-page__refund-form {
    width: 100%;
    max-width: 343px;

    & .login-page__error-message {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
