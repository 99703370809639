@import '../../pages/style-guide.scss';

.checkbox-row {
  display: flex;
  width: 100%;
}

.checkbox-label {
  width: 100%;
  cursor: pointer;
}

.checkbox-inp {
  width: 24px;
  height: 24px;
  margin: 0 16px 0 0;
  vertical-align: middle;
  -webkit-appearance: none;
  cursor: pointer;

  &:checked {
    background-image: url(../../images/components/Checkbox/checked.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  &:not(:checked):hover {
    background-image: url(../../images/components/Checkbox/checked-hover.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}
