@import './style-guide';

.page__title {
  color: $text-headers;
}

.page__tab-list {
  display: flex;
  padding: 8px 16px;
  margin: 28px 0 34px 0;
  list-style: none;
  border-radius: 8px;
  background-color: $background-grey-normal;
}

.page__tab {
  margin-right: 8px;
  padding: 10px 12px;
  color: $text-headers;
  cursor: pointer;

  &.react-tabs__tab--selected {
    background: $primary-accent;
    border-radius: 6px;
    color: $text-dark-background;
  }

  :last-child {
    margin-right: 0;
  }
}

*::selection {
  background-color: $primary-accent-light;
}

* {
  -webkit-tap-highlight-color: transparent;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .page__title {
    position: relative;
    z-index: 20;
    background: white;
    padding: 0 16px;
  }
  .page__tabs {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .page__tab-list {
    overflow-x: auto;
    border-radius: 0;
    margin: 0;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }
}
