@import '../../pages/style-guide';

.login-page__container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.login-page__content {
  position: absolute;
  top: 39%;
  left: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 343px;
  background: #fff;
  -webkit-transform: translate(-50%, -39%);
  transform: translate(-50%, -39%);
}

.login-page__logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 158px;
  margin-bottom: 40px;
}

.login-page__input-container {
  width: 100%;
}

.login-page__input-container.password {
  margin-top: 24px;
}

.login-page-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 24px;
}

.login-page-button {
  width: 100%;
}

.logo-text-wrapper {
  display: flex;
  align-items: center;
  color: $text-headers;
}

.login-page__error-message {
  margin-top: 8px;
  color: $input-warning-error;
  align-self: flex-start;
}

.login-page__logo {
  margin-right: 20px;
}

.login-page__restore-password-btn {
  align-self: flex-start;
  margin-top: 16px;
  color: $primary-accent;
}

.login-page__create-account {
  margin-top: 28px;
  align-self: flex-start;
  color: $text-normal;
}

.login-page__create-account-btn {
  color: $primary-accent;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .login-page__error-message {
    margin-left: 16px;
    margin-right: 16px;
  }

  .layout__content-container.unauthorized {
    & .login-page__error-message {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
