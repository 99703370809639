@import '../../../style-guide';

.booking-date-group__container {
  margin-bottom: 32px;
}

.booking-date-group__date {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $text-headers;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .booking-date-group__container {
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .booking-date-group__date {
    display: none;
  }
}
