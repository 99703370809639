@import '../../../style-guide';

.slideshow__container {
  position: relative;
  width: 100%;
  height: 472px;
  margin-bottom: 44px;
  background-color: $background-grey-normal;

  &.no-images {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: $text-labels;
  }

  &.audio {
    height: 50px;
  }
}

.slideshow__each-fade {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 472px;

  & img {
    display: block;

    &.fit-by-width {
      width: 100%;
      height: auto;
    }

    &.fit-by-height {
      height: 100%;
      width: auto;
    }
  }

  &.audio {
    height: 50px;

    & video::-webkit-media-controls-panel {
      background-color: #EAEDF2;
    }
  }
}

.slideshow__arrow-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $background-grey-normal;
  cursor: pointer;

  &.left {
    left: 12px;

    & svg {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translateY(-50%) translateX(-40%);
    }
  }

  &.right {
    right: 12px;

    & svg {
      position: absolute;
      top: 50%;
      left: 60%;
      transform: translateY(-50%) translateX(-60%);
    }
  }

  &:hover {
    background-color: $background-grey-pressed;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .slideshow__each-fade {
    height: 375px;
  }

  .slideshow__container {
    height: 375px;
  }
}
