@import '../../MainPage/style-guide.scss';


.usefullcategories__container{
  display: flex;
  align-self: stretch;
  background-color: #EAEDF2;
  padding: 80px 0;
  flex: 1;
}

.usefullcategories__content{
  flex: 10;
  display: flex;
  flex-direction: column;
}

.usefullcategories__navigation-links{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 30px;
  a{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    margin-right: 10px;
    color: #92A4B5 !important;
  }
  p{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    margin-left: 10px;
    color: #00BFB4;
  }
}

.usefullcategories__navigation-links_1024{
  display: none;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 30px;
  a{
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #92A4B5 !important;
    white-space: nowrap;
  }
}

.usefullcategories__header{
  display: flex;
  font-size: 48px;
  font-family: 'Inter-Black';
  line-height: 61px;
  letter-spacing: 0.02em;
  color: #343743;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.usefullcategories__cards-page{
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  align-content: start;
  grid-gap: 10px;
  margin-top: 30px;
}

.usefullcategories__card-item-page{
  flex: 1;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.04);
}

.usefullcategories__card-item-content{
  display: flex;
  flex-direction: row;
}

.usefullcategories__card-item-pictire-container{
  flex: 1;
}

.usefullcategories__card-item-pictire{
  width: 160px;
  height: 170px;
  border-radius: 8px;
}

.usefullcategories__card-description{
 flex: 3;
  margin-left: 10px;
}

.usefullcategories__card-description-top{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.usefullcategories__card-description-bottom{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  width: 100%;
  margin-top: 10px;
}

.usefullcategories__card-description-title {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Inter-SemiBold';
  font-size: 20px;
  line-height: 32px;
  color: $black-amber;
  text-align: start;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.usefullcategories__card-description-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Inter-Regular';
  font-size: 18px;
  line-height: 29px;
  text-align: start;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #072D4B;
  opacity: 0.6;
}

@media screen and (max-width: 1366px) and(min-width: 1025px){
  .usefullcategories__cards-page{
    grid-template-columns: repeat(2, 1fr);
  }

  .usefullcategories__card-item-page{
    padding: 10px;
  }

  .usefullcategories__card-item-pictire{
    width: 133px;
    height: 135px;
    border-radius: 8px;
  }

  .usefullcategories__card-description-title {
    font-size: 16px;
    line-height: 26px;
  }

  .usefullcategories__card-description-text {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1024px) and(min-width: 801px){
  .usefullcategories__navigation-links{
    display: none;
  }

  .usefullcategories__navigation-links_1024{
    display: flex;
  }

  .usefullcategories__container{
    padding: 60px 0;
  }

  .usefullcategories__cards-page{
    grid-template-columns: repeat(2, 1fr);
  }

  .usefullcategories__card-item-page{
    padding: 10px;
  }

  .usefullcategories__card-item-pictire{
    width: 115px;
    height: 135px;
    border-radius: 8px;
  }

  .usefullcategories__card-description-title {
    font-size: 16px;
    line-height: 26px;
  }

  .usefullcategories__card-description-text {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 800px) and(min-width: 1px){
  .usefullcategories__navigation-links{
    display: none;
  }

  .usefullcategories__navigation-links_1024{
    display: flex;
  }

  .usefullcategories__container{
    padding: 60px 16px;
  }

  .usefullcategories__cards-page{
    grid-template-columns: minmax(0, 500px);
  }

  .usefullcategories__card-item-page{
    padding: 10px;
  }

  .usefullcategories__card-item-pictire{
    width: 115px;
    height: 135px;
    border-radius: 8px;
  }

  .usefullcategories__card-description-title {
    font-size: 16px;
    line-height: 26px;
  }

  .usefullcategories__card-description-text {
    font-size: 14px;
    line-height: 22px;
  }
}