.prices__container {
  margin-top: 24px;
  padding: 20px 16px;
  border-radius: 8px;
  border: 2px solid #EAEDF2;
}

.prices__wrapper {
  &.generic {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    & .prices__row {
      flex-direction: column;
      align-items: flex-start;

      &:not(:last-child) {
        padding-bottom: 0;
        border-bottom: none;
      }

      & .prices__row-title {
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #92A4B5;
      }

      & .price__container {
        width: 228px;
      }
    }
  }
}

.prices__title {
  margin-bottom: 20px;
  color: #343743;
}

.price__container {
  width: 142px;
}

.price__post-label {
  margin-top: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #92A4B5;
}

.price__sup {
  position: relative;
  top: -0.06em;
  font-size: 100%;
}

.prices__row {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;

  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 2px solid #EAEDF2;
  }
}

.prices__row-title {
  width: 202px;
  color: #5C6E7F;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .prices__container {
    margin: 24px 16px 0 16px;
    padding: 12px;
  }

  .prices__row {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }

  .prices__wrapper {
    &.generic {
      flex-direction: column;
      & .prices__row {
        row-gap: 0;
      }
    }
  }

  .price__container {
    width: 100%;
  }
}
