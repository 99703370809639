.popup-menu {
  position: absolute;
  min-width: 300px;
  z-index: 100;
  margin-top: 8px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(52, 55, 67, 0.12);
}

@media screen and (max-width: 1365px) and (min-width: 841px) {
  .service-menu {
    top: 24px;
    right: 10px;
    margin-top: 16px;
    min-width: 200px;
  }
}


@media screen and (max-width: 840px) and (min-width: 320px) {
  .service-menu {
    top: 16px;
    right: 10px;
    margin-top: 16px;
    min-width: 200px;
  }
}
