.tools__container {
  height: 565px;
  min-height: 565px;
  width: 100%;
  overflow-x: hidden;
}

.tools__content-container {
  position: relative;
  width: 1920px;
  margin: 0 auto;
}

.tools__iphone-img {
  position: absolute;
  top: 63px;
  left: 251px;
  width: 323px;
  height: 447px;
  background-image: url(../../../images/InfoProviders/tools-iphone.png);
  background-repeat: no-repeat;
}

.tools__mac-book-img {
  position: absolute;
  top: 83px;
  right: 21px;
  width: 715px;
  height: 398px;
  background-image: url(../../../images/InfoProviders/tools-mac-book.png);
  background-repeat: no-repeat;
}

.tools__info-title {
  padding-top: 138px;
  margin-left: 659px;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 61px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #00BFB4;
}

.tools__devices {
  margin-top: 64px;
  margin-left: 611px;
  display: flex;
  gap: 60px;
}

.tools__device {
  width: 393px;
  height: 184px;
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.01em;
  color: #4E505A;
  border-left: 2px solid #FFCB31;

  &.iphone {
    width: 322px;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1365px) {
  .tools__container {
    height: 428px;
    min-height: 428px;
  }

  .tools__content-container {
    width: 1366px;
  }

  .tools__iphone-img {
    top: 43px;
    left: 114px;
    width: 238px;
    height: 329px;
    background-size: cover;
  }

  .tools__mac-book-img {
    top: 70px;
    right: -85px;
    width: 574px;
    height: 320px;
    background-size: cover;
  }

  .tools__info-title {
    padding-top: 49px;
    margin-left: 500px;
    font-size: 36px;
    line-height: 46px;
  }

  .tools__devices {
    margin-top: 81px;
    margin-left: 381px;
    gap: 109px;
  }

  .tools__device {
    width: 264px;
    font-size: 20px;
    line-height: 32px;

    &.iphone {
      width: 249px;
    }
  }
}

@media screen and (max-width: 1366px) and (min-width: 1023px) {
  .tools__container {
    height: 417px;
    min-height: 417px;
  }

  .tools__content-container {
    width: 1024px;
  }

  .tools__iphone-img {
    top: 117px;
    left: 18px;
    width: 183px;
    height: 253px;
    background-size: cover;
  }

  .tools__mac-book-img {
    top: 121px;
    right: -142px;
    width: 455px;
    height: 253px;
    background-size: cover;
  }

  .tools__info-title {
    padding-top: 54px;
    margin-left: 364px;
    font-size: 30px;
    line-height: 38px;
  }

  .tools__devices {
    margin-top: 95px;
    margin-left: 212px;
    gap: 20px;
  }

  .tools__device {
    width: 325px;
    font-size: 20px;
    line-height: 32px;

    &.iphone {
      width: 280px;
    }
  }
}

@media screen and (max-width: 1023px) and (min-width: 374px) {
  .tools__container {
    height: 468px;
    min-height: 468px;
  }

  .tools__content-container {
    width: 375px;
  }

  .tools__iphone-img {
    top: 73px;
    left: unset;
    right: 16px;
    width: 183px;
    height: 253px;
    background-size: cover;
  }

  .tools__mac-book-img {
    top: 276px;
    right: unset;
    left: -135px;
    width: 341px;
    height: 190px;
    background-size: cover;
  }

  .tools__info-title {
    width: 180px;
    padding-top: 33px;
    margin-left: 16px;
    font-size: 28px;
    line-height: 36px;
  }

  .tools__devices {
    margin-top: 32px;
    margin-left: 0;
    padding-left: 21px;
    gap: 0;
    flex-direction: column;
  }

  .tools__device {
    height: 117px;
    font-size: 16px;
    line-height: 26px;
    padding: 0;
    border-left: none;
    align-self: flex-end;

    &.iphone {
      width: 184px;
      align-self: flex-start;
    }

    & .tools__device-text {
      display: block;
      width: 187px;
      background-color: white;
      margin-top: -16px;
      padding-top: 36px;
    }

    &.macbook {
      width: 324px;
      position: absolute;
      z-index: 10;
      top: 278px;
      left: 187px;
      background-color: white;
    }
  }
}

@media screen and (max-width: 374px) and (min-width: 320px) {
  .tools__container {
    height: 468px;
    min-height: 468px;
  }

  .tools__content-container {
    width: 375px;
  }

  .tools__iphone-img {
    top: 73px;
    left: unset;
    right: 16px;
    width: 183px;
    height: 253px;
    background-size: cover;
  }

  .tools__mac-book-img {
    top: 276px;
    right: unset;
    left: -135px;
    width: 341px;
    height: 190px;
    background-size: cover;
  }

  .tools__info-title {
    width: 180px;
    padding-top: 33px;
    margin-left: 16px;
    font-size: 28px;
    line-height: 36px;
  }

  .tools__devices {
    margin-top: 32px;
    margin-left: 0;
    padding-left: 21px;
    gap: 0;
    flex-direction: column;
  }

  .tools__device {
    height: 117px;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
    border-left: none;
    align-self: flex-end;

    &.iphone {
      width: 168px;
      align-self: flex-start;
    }

    & .tools__device-text {
      display: block;
      width: 160px;
      background-color: white;
      margin-top: -16px;
      padding-top: 36px;
    }

    &.macbook {
      width: 324px;
      position: absolute;
      z-index: 10;
      top: 278px;
      left: 187px;
      background-color: white;
    }
  }
}

