@import '../../pages/style-guide';

.pagination-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
}

.pagination-container__page-btn {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &.hidden {
    display: none;
  }
}

.pagination-container__page-btn span {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 36px;
  height: 36px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: $button-normal;
}

.pagination-container__page {
  cursor: pointer;
  border-radius: 8px;

  &:not(.pagination-container__page-active):hover {
    background-color: $background-white-hover;
  }

  &:not(.pagination-container__page-active):active {
    background-color: $background-white-pressed;
  }
}

.pagination-container__truncable-btn {
  cursor: default;
}

.pagination-container__page-active {
  background-color: $background-grey-normal;
  border-radius: 8px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .pagination-container {
    margin-bottom: 16px;
    padding: 0 16px;
  }
  .pagination-container__page-btn {
    gap: 4px;
  }

  .pagination-container__page-btn span {
    width: 30px;
    height: 30px;
  }
}
