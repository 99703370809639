@import '../../../../../style-guide';

.route__container {
  padding-left: 4px;
}

.route__stop-container {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 8px;
  }
}

.route__first-row {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 12px;
  margin-bottom: 20px;
}

.route__stop-name {
  width: 343px;
}

.route__stop-desc {
  width: 100%;
  margin-top: 20px;
}

.route__add-stop-btn {
  display: inline-block;
  width: 300px;
  margin-top: 12px;

  & .services__add-btn-icon path {
    fill: $button-normal;
  }
}

.route__delete-btn {
  width: 24px;
  height: 24px;
  border-radius: 7px;
  background: $background-grey-normal;

  &:hover {
    background: $background-grey-hover;
  }

  &:active {
    background: $background-grey-pressed;
  }
}

.route__view-stop-container {
  position: relative;
  padding-left: 24px;
  padding-bottom: 28px;
  border-left: 6px solid $primary-accent;

  &:last-child {
    border-left: 6px solid transparent;
  }
}

.route__stop-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -1px;
  left: -13px;
}

.route__view-stop-name {
  color: $text-headers;
}

.route__view-stop-desc {
  margin-top: 4px;
  color: $text-normal;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .route__add-stop-btn {
    display: flex;
    width: 100%;
  }
}
