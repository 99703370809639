@import '../../pages/style-guide';

.phone-input__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 7px 12px;
  background-color: $background-grey-normal;
  border-radius: 8px;
  border: 1px solid $background-grey-normal;
  z-index: 2000000;

  &.error:not(.focus) {
    border: 1px solid $input-warning-error;
  }

  &:not(:disabled):hover {
    background-color: $background-grey-hover;

    & input {
      background-color: $background-grey-hover;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $background-grey-hover inset;
      }
    }
  }

  &.focus {
    box-shadow: 0 0 0 2px $input-active-stroke;
  }
}

.phone-input__container input {
  max-width: 210px;
  color: $text-normal;
  background-color: $background-grey-normal;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;

  &::placeholder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: $text-labels;
  }

  &:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px $background-grey-normal inset;
    -webkit-text-fill-color: $text-normal;
  }
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .phone-input__container input {
    max-width: calc(100% - 105px);
  }
}





