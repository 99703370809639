@import '../../pages/style-guide';

.search-input__container {
  position: relative;
}

.search-input {
  width: 100%;
  height: 44px;
  padding: 0 44px 0 12px;
  color: $text-normal;
  background: $background-grey-normal;
  border-radius: 8px;
  cursor: pointer;

  &::placeholder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: $text-labels;
  }

  &:placeholder-shown {
    text-indent: 32px;
  }

  &:not(:placeholder-shown) {
    & ~ .search-input__search-icon {
      display: none;
    }

    &:focus {
      & ~ .search-input__clear-button {
        display: block;
      }
    }
  }

  &:focus {
    text-indent: 0;
    outline: none;

    & ~ .search-input__search-icon {
      display: none;
    }
  }

  &:focus::placeholder {
    opacity: 0;
  }
}

.search-input__clear-button {
  position: absolute;
  top: 10px;
  right: 12px;
  display: none;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-input__search-icon {
  position: absolute;
  top: 10px;
  left: 12px;
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
