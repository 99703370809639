.rates__container {
  padding: 20px 16px;
  border-radius: 8px;
  border: 2px solid #EAEDF2;
}

.rates__title {
  margin-bottom: 24px;
  color: #343743;
}

.rates__content-container {
  padding-bottom: 32px;
  margin-bottom: 32px;
  display: flex;
  column-gap: 64px;
  row-gap: 32px;
  flex-wrap: wrap;
  border-bottom: 2px solid #EAEDF2;

  &.view {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.rates__rate {
  width: 312px;
  height: 68px;
}

@media screen and (max-width: 840px) and (min-width: 320px) {
  .rates__container {
    margin: 0 16px;
    padding: 12px;
  }
}